import axios from 'axios';
import config from '../config';

const baseApiUrl = config.BASE_API_URL;
const publicApiUrl = config.Public_API_URL;
const uname=config.Public_ClientId;
const pwd=config.Public_ClientSecret;
export function GetAccountProfileInfo(programID, clientSSNorTIN) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Clients/Accounts/Summary',
    data: {
      ProgramID: programID,
      ClientSSNorTaxID: clientSSNorTIN,
    },
  });
}
export function GetContactDetailsById(contacId) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'contacts/' + contacId,
  });
}
export function GetPendingActivity(programID, clientSSNorTIN, rowCount) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'PendingTransactions/PendingActivity',
    data: {
      ProgramID: programID,
      ClientSSNorTaxID: clientSSNorTIN,
      RowCount: rowCount,
    },
  });
}
export function GetCurrentBalanceInfo(
  programID,
  clientSSNorTIN,
  AccountUniqueID
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Accounts/CurrentBalanceSummary',
    data: {
      ProgramId: programID,
      ClientSSNorTaxID: clientSSNorTIN,
      AccountUniqueID: AccountUniqueID,
    },
  });
}

export function GetACHPayeeInstructions(ssnortin, accountUniqueID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Clients/ACHPayeeInstructions',
    data: {
      ClientSSNorTaxID: ssnortin,
      AccountUniqueID: accountUniqueID,
    },
  });
}

export function GetWirePayeeInstructions(ssnortin, accountUniqueID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Clients/WirePayeeInstructions',
    data: {
      ClientSSNorTaxID: ssnortin,
      AccountUniqueID: accountUniqueID,
    },
  });
}

export function GetP2PInstructions(accountUniqueID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Accounts/LinkedPeerAccounts',
    data: {
      AccountUniqueID: accountUniqueID,
      FundPendingTransactionTypeClassID: 25,
    },
  });
}

export function GetP2PInstructionsBySSNORTIN(ssnorTIN) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Client/Account/LinkedPeerClientAccounts',
    data: {
      SSNORTIN: ssnorTIN,
      FundPendingTransactionTypeClassID: 25,
    },
  });
}
export function GetTeamMemberDetails(ssnorTIN) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'Accounts/' + ssnorTIN + '/TeamMembers',
  });
}

export function GetTeamMemberDetailsByAccount(accountUniqueId) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'Accounts/' + accountUniqueId + '/GetTeamMemberDetailsByAccount',
  });
}


export function GetFiscalYear(ssnorTIN) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'Reports/' + ssnorTIN + '/fiscalyear',
  });
}

export function GetTeamMemberAccountDetails(contactID, ssnorTIN) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Accounts/TeamMemberAccountDetails',
    data: {
      ContactID: contactID,
      ClientSSNorTaxID: ssnorTIN,
    },
  });
}


export function GetClientTeamManagementInfo(ssnorTIN) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'Clients/' + ssnorTIN + '/ClientTeamManagementInfo'
  });
}


export function GetPendingActivityAdditionalInfo(
  linkID,
  pendingTransactionTypeID
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'PendingTransactions/PendingActivity/AdditionalInfo',
    data: {
      LinkID: linkID,
      PendingTransactionTypeID: pendingTransactionTypeID,
    },
  });
}

// export function GetTransactionTypeIDsByFundGroupId(fundGroupId) {
//   return axios.request({
//     method: 'GET',
//     url: baseApiUrl + 'FundGroups/' + fundGroupId + '/TransactionTypeIDs',
//   });
// }

export function GetTransactionTypeIDsByFundGroupId(fundGroupId, prgId) {
  return axios.request({
    method: 'GET',
    url:
      baseApiUrl +
      'FundGroups/' +
      fundGroupId +
      '/FundTransactionTypeIDs/' +
      prgId,
  });
}

export function GetWebUserswAccountTransactionPermissions(
  clientSSNorTIN,
  accountUniqueId,
  pendingTransactionTypeID
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'WebUsers/AccountTransaction/Permissions',
    data: {
      AccountUniqueID: accountUniqueId,
      ClientSSNorTaxID: clientSSNorTIN,
      PendingTransactionTypeClassID: pendingTransactionTypeID,
    },
  });
}
export function GetAccountMonthlySummary(
  programId,
  clientSSNorTIN,
  convertedDateValue
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Reports/ConnectMonthlySummary',
    data: {
      ProgramId: programId,
      ClientSSNorTaxID: clientSSNorTIN,
      convertedDateValue: convertedDateValue,
    },
  });
}

export function GetPendingTransactionStatus(linkID) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'PendingTransactions/' + linkID + '/Status',
  });
}

export function VoidTransaction(pendingTransactionTypeID, linkID) {
  return axios.request({
    method: 'PUT',
    url: baseApiUrl + 'PendingTransactions/Reverse',
    data: {
      LinkID: linkID,
      PendingTransactionTypeID: pendingTransactionTypeID,
    },
  });
}

export function GetWebUserPassword() {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'WebUsers/Info',
  });
}

export function UpdateAuthenticationAttempt(isFailure) {
  axios
    .put(baseApiUrl + 'WebUsers/AuthenticationAttempt?isFailure=' + isFailure)
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    });
}

export function GetCurrentYields(programID) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'Programs/' + programID + '/Rates',
  });
}

export function GetTermYields(fundGroupID) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'FundGroup/' + fundGroupID + '/TermRates',
  });
}

export function GetStopPaymentDetails(ssnorTIN) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'StopPayment/Details',
    data: {
      SSNorTIN: ssnorTIN,
    },
  });
}

export function GetPendingActivityDisplayPermission(
  ssnortin,
  accountUniqueID = null
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'PendingTransactions/DisplayPermission',
    data: {
      ClientSSNorTaxID: ssnortin,
      AccountUniqueID: accountUniqueID,
    },
  });
}

export function GetClientLevelTransBankingInstPermission(
  ssnortin,
  accountUniqueID = null
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Clients/TransBankingInst/Permission',
    data: {
      ClientSSNorTaxID: ssnortin,
      AccountUniqueID: accountUniqueID,
    },
  });
}

export function GetClientOrAccountLevelTransPermission(
  ssnortin,
  accountUniqueID = null
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Clients/Trans/Permission',
    data: {
      ClientSSNorTaxID: ssnortin,
      AccountUniqueID: accountUniqueID,
    },
  });
}

export function AddEonCachedAccounts(programID, clientSSNorTIN) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'WebUsers/Clients/CachedAccounts',
    data: {
      ProgramID: programID,
      ClientSSNorTaxID: clientSSNorTIN,
    },
  });
}

export function getContactLastPasswordChange() {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'profile/getContactLastPasswordChange',
  });
}

export function getNotificationPreferences(contactID, ssnorTIN) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'WebUsers/GetConnectNotificationPreferences',
    data: {
      ContactID: contactID,
      ClientSSNorTaxID: ssnorTIN,
    },
  });
}
export function getContactProfile() {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'profile/getContactProfile',
  });
}

export function updateProfileContact(
  contactID,
  ext,
  mvarEmailAddress,
  mvarFaxNumber,
  mvarMobileNumber,
  mvarPhoneNumber,
  mvarSalutation,
  mvarSecretAnswer,
  mvarSecretQuestion,
  mvarTitle
) {
  return axios.request({
    method: 'PUT',
    url: baseApiUrl + 'profile/contact',
    data: {
      contactID: contactID,
      emailAddress: mvarEmailAddress,
      title: mvarTitle,
      secretQuestion: mvarSecretQuestion,
      secretAnswer: mvarSecretAnswer,
      mobileNumber: mvarMobileNumber,
      faxNumber: mvarFaxNumber,
      salutation: mvarSalutation,
      phoneAddionalInfo: ext,
      phoneNumber: mvarPhoneNumber,
    },
  });
}

export function GetMonthlyStatements(
  programID,
  ssnorTIN,
  startDate = null,
  endDate = null
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Statements/Monthly',
    data: {
      ProgramId: programID,
      ClientSSNorTaxID: ssnorTIN,
      StartDate: startDate,
      EndDate: endDate,
    },
  });
}

export function DownloadConsolidatedStatement(slipsheetid, date) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Statements/Consolidated/Download',
    data: {
      SlipsheetID: slipsheetid,
      Date: date,
    },
  });
}

export function DownloadAccountStatement(path, filename, date) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Statements/Accounts/Download',
    data: {
      Path: path,
      FileName: filename,
      Date: date,
    },
  });
}

export function GetDailyConfirms(ssnorTIN, startDate = null, endDate = null) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Statements/DailyConfirms',
    data: {
      ClientSSNorTaxID: ssnorTIN,
      StartDate: startDate,
      EndDate: endDate,
    },
  });
}

export function DownloadDailyConfirms(path, filename) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Statements/DailyConfirms/Download',
    data: {
      FilePath: path,
      FileName: filename,
    },
  });
}
//,startDate,endDate
export function GetDocuments(programId, clientSSNorTaxID, startDate, endDate) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Documents/DetailsByDate',
    data: {
      ProgramId: programId,
      ClientSSNorTaxID: clientSSNorTaxID,
      StartDate: startDate,
      EndDate: endDate,
    },
  });
}

export function DownloadDocument(Type, FileUniqueId) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Reports/DocumentByTypeAndID_V2',
    data: {
      Type: Type,
      FileUniqueId: FileUniqueId,
    },
  });
}

export function FAQ(programID) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'GenericApi/FAQ/' + programID,
  });
}

export function SecureContactUpload(frmData, config) {
  return axios.post(
    baseApiUrl + 'WebUsers/Clients/SecureContactUpload',
    frmData,
    config
  );
}

export function UpdateNotificationPreferences(
  contactNotifications,
  ssnOrTin,
  contactId
) {
  return axios.request({
    method: 'PUT',
    url: baseApiUrl + 'UpdateNotificationPreferences',
    data: {
      contactNotificationPreferences: contactNotifications,
      ClientSSNorTaxID: ssnOrTin,
      ContactId: contactId,
    },
  });
}

export function UpdateNotificationDeliveryOptions(
  ContactID,
  SSNORTIN,
  Check,
  StatementType
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'WebUsers/UpdateNotificationDeliveryOptions',
    data: {
      ContactID: ContactID,
      SSNORTIN: SSNORTIN,
      Check: Check,
      StatementType: StatementType,
    },
  });
}

export function SendUnSecureContactEmail(
  ContactName,
  EmailAddress,
  Subject,
  CallbackNumber,
  Message
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'GenericApi/UnSecureContact',
    data: {
      ContactName: ContactName,
      EmailAddress: EmailAddress,
      Subject: Subject,
      CallbackNumber: CallbackNumber,
      Message: Message,
    },
  });
}

export function GetClientDetails(ssnortin) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'Clients/' + ssnortin + '/Details',
  });
}

export function GetProgramPhoneNumber(programID) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'Programs/' + programID + '/PhoneNumber',
  });
}

export function GetAlerts(programID) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'Programs/' + programID + '/Alerts',
  });
}

export function GetWebUserVisits(webUserVisitId) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'WebUserVisits/' + webUserVisitId + '/ValidateWebUserVisits',
  });
}

export function DismissAlert(programID, newsAndAlertID) {
  return axios.request({
    method: 'PUT',
    url: baseApiUrl + 'WebUsers/NewsAlerts/Dismiss',
    data: {
      ProgramId: programID,
      NewsAndAlertID: newsAndAlertID,
    },
  });
}

export function IsValidRecaptcha(token) {
  return axios.request({
    method: 'POST',
    url: publicApiUrl + 'recaptcha',
    auth: {
      username:uname,
      password:pwd
  },
    data: {
      response: token,
    },
  });
}

export function mfaVerification(token) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'GenericApi/VerifyMFA',
    data: {
      response: token,
    },
  });
}
export function GetWebUserInfo() {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'WebUsers',
  });
}

export function GetTransactableAccounts(SSNORTIN, ProgramID,FundPendingTransactionTypeClassID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Transactions/TransactableAccounts',
    data: {
      SSNORTIN:SSNORTIN,
      ProgramID: ProgramID,
      FundPendingTransactionTypeClassID: FundPendingTransactionTypeClassID,
    },
  });
}

export function SubmitWireTransaction(PostingDate, Amount,BankName,FundUniqueID,AccountUniqueID ) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'ConnectWireNotification/submit',
    data: {
      PostingDate:PostingDate,
      Amount: Amount,
      BankName:BankName,
      FundUniqueID: FundUniqueID,
      AccountUniqueID: AccountUniqueID
    },
  });
}

export function IsDuplicateTransactionSingleAccount(PostedDate, FromAccountUniqueID, Amount, FromTransactionTypeId ) {
    return axios.request({
      method: 'POST',
      url: baseApiUrl + 'Transactions/DuplicateTransactions?isSingleAccount=true',
      data: {
        PostedDate:PostedDate,
        FromAccountUniqueID: FromAccountUniqueID,
        ToAccountUniqueID:"0",
        Amount:Amount,
        FromTransactionTypeId: FromTransactionTypeId,
      },
    });
}

export function GetWireInstructions(fundUniqueID ) {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'Funds/'+fundUniqueID+'/ConnectWireInstructions',
  });
}

export function GetTransactionDefaultDay(FundUniqueID, PendingTransactionTypeClassID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Transactions/DefaultDay',
    data: {
      FundUniqueID:FundUniqueID,
      PendingTransactionTypeClassID: PendingTransactionTypeClassID,
    },
  });
}

export function IsFundValidBusinessDay(Date,FundUniqueID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Transactions/IsValidBusDay',
    data: {
      Date:Date,
      FundUniqueID:FundUniqueID,
    },
  });
}

export function GetACHInstructions(AccountUniqueId) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Accounts/ACHInstructions',
    data: {
      AccountUniqueId:AccountUniqueId,
    },
  });
}

export function GetAccountWireInstructions(AccountUniqueId) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Accounts/WireInstruction',
    data: {
      AccountUniqueId:AccountUniqueId,
    },
  });
}

export function SubmitACHRedemption(PostingDate, Amount,InstructionsID,FundUniqueID,AccountUniqueID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'ConnectRedemptions/submitACH',
    data: {
      PostingDate:PostingDate,
      Amount: Amount,
      InstructionsID:InstructionsID,
      FundUniqueID: FundUniqueID,
      AccountUniqueID: AccountUniqueID
    },
  });
}

export function ValidateTransaction(PostingDate,DefaultDate, Amount,FundUniqueID,AccountUniqueID, PendingTransactionTypeID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Transactions/Validate',
    data: {
      PostingDate:PostingDate,
      Amount: Amount,
      DefaultDate:DefaultDate,
      FundUniqueID: FundUniqueID,
      AccountUniqueID: AccountUniqueID,
      PendingTransactionTypeID: PendingTransactionTypeID
    },
  });
}
export function SubmitWireRedemption(PostingDate, Amount,InstructionsID,FundUniqueID,AccountUniqueID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'ConnectRedemptions/submitWire',
    data: {
      PostingDate:PostingDate,
      Amount: Amount,
      InstructionsID:InstructionsID,
      FundUniqueID: FundUniqueID,
      AccountUniqueID: AccountUniqueID
    },
  });
}

export function GetACHInstructionsFilteredData(accountUniqueId) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'ConnectACHPurchase/ACHInstructions',
    data: {
      AccountUniqueId:accountUniqueId,
    },
  });
}

export function SubmitACHPurchase(ACHInstructionID, AccountUniqueID,Amount,FundUniqueID,SSNoRTIN,PostingDate) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'ConnectACHPurchase/Submit',
    data: {
      ACHInstructionID:ACHInstructionID,
      AccountUniqueID: AccountUniqueID,
      Amount:Amount,
      FundUniqueID: FundUniqueID,
      SSNoRTIN: SSNoRTIN,
      PostingDate: PostingDate
    },
  });
}

export function CreateOrUpdateClientFavorite(WebUserKey,SSNORTIN,ProgramID,IsActive) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Clients/InsertOrUpdateClientFavorite',
    data: {
      WebUserkey:WebUserKey,
      ClientSSNorTaxID:SSNORTIN,
      ProgramID:ProgramID,
      IsActive:IsActive
    },
  });
}
export function IsValidStopPaymentRequest(AccountUniqueID,FundUniqueID,CheckDate,CheckNumber,CheckPayee,ReasonForStop,WebUserAccessType,CheckAmount) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'ConnectStopPayment/ValidateStopPaymentRequest',
    data: {
      AccountUniqueID: AccountUniqueID,
      FundUniqueID:FundUniqueID,
      CheckDate:CheckDate,
      CheckNumber : CheckNumber,
      CheckPayee:CheckPayee,
      ReasonForStop : ReasonForStop,
      WebUserAccessType : WebUserAccessType,
      CheckAmount: CheckAmount
    },
  });
}

export function IsDuplicateTransactionStopPayments(PostedDate, FromAccountUniqueID,ToAccountUniqueID, Amount, FromTransactionTypeId ) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Transactions/DuplicateTransactions?isSingleAccount=true',
    data: {
      PostedDate:PostedDate,
      FromAccountUniqueID: FromAccountUniqueID,
      ToAccountUniqueID:ToAccountUniqueID,
      Amount:Amount,
      FromTransactionTypeId: FromTransactionTypeId,
    },
  });
}

export function SubmitStopPayment(AccountUniqueID,FundUniqueID,CheckDate,CheckNumber,CheckPayee,ReasonForStop,WebUserAccessType,CheckAmount) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'ConnectStopPayment/SubmitStopPayment',
    data: {
      AccountUniqueID: AccountUniqueID,
      FundUniqueID:FundUniqueID,
      CheckDate:CheckDate,
      CheckNumber : CheckNumber,
      CheckPayee:CheckPayee,
      ReasonForStop : ReasonForStop,
      WebUserAccessType : WebUserAccessType,
      CheckAmount: CheckAmount
    },
  });
}
export function GetAccountBalance(FundUniqueID, AccountUniqueID,PendingTransactionTypeID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Accounts/Balances',
    data: {
      FundUniqueID:FundUniqueID,
      AccountUniqueID: AccountUniqueID,
      PendingTransactionTypeID: 5,
    },
  });
}
export function SubmitTransferTransaction(PostingDate, Amount,FundUniqueID,FromAccountUniqueID,ToAccountUniqueID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'ConnectTransferController/SubmitTransfer',
    data: {
      PostingDate:PostingDate,
      Amount: Amount,
      FundUniqueID: FundUniqueID,
      FromAccountUniqueID: FromAccountUniqueID,
      ToAccountUniqueID: ToAccountUniqueID
    },
  });
}

export function SubmitExchangeTransaction(PostingDate, Amount,FromFundUniqueID,ToFundUniqueID,AccountUniqueID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'ConnectTransferController/SubmitExchange',
    data: {
      PostingDate:PostingDate,
      Amount: Amount,
      AccountUniqueID: AccountUniqueID,
      FromFundUniqueID: FromFundUniqueID,
      ToFundUniqueID: ToFundUniqueID
    },
  });
}
export function SubmitProgramTransferRequest(PostingDate, Amount,FromFundUniqueID,ToFundUniqueID,FromAccountUniqueID,ToAccountUniqueID,E2EInstructionID) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'ConnectTransferController/SubmitProgramTransferRequest',
    data: {
      PostingDate:PostingDate,
      Amount: Amount,
      FromFundUniqueID: FromFundUniqueID,
      ToFundUniqueID: ToFundUniqueID,
      FromAccountUniqueID:  FromAccountUniqueID,
      ToAccountUniqueID:ToAccountUniqueID,
      E2EInstructionID:E2EInstructionID
      
    },
  });
}
export function GetP2PTransferInstructions(
  fundUniqueID,
  accountUniqueID
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Accounts/E2EInstructions',
    data: {
      FundUniqueID: fundUniqueID,
      AccountUniqueID: accountUniqueID
    },
  });
}
export function IsDuplicateTransferTransactionSingleAccount(isSingleAccount,PostedDate, FromAccountUniqueID,ToAccountUniqueID, Amount, FromTransactionTypeId,ToTransactionTypeId ) {
  return axios.request({
      method: 'POST',
      url: baseApiUrl + 'Transactions/DuplicateTransactions?isSingleAccount='+isSingleAccount,
      data: {
        PostedDate:PostedDate,
        FromAccountUniqueID: FromAccountUniqueID,
        ToAccountUniqueID:ToAccountUniqueID,
        Amount:Amount,
        FromTransactionTypeId: FromTransactionTypeId,
        ToTransactionTypeId: ToTransactionTypeId
      },
    });
}

export function GetAsOfDate() {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'GenericApi/AsOfDate',
  });
}

export function GetDividendsHistoryMonthYear() {
  return axios.request({
    method: 'GET',
    url: baseApiUrl + 'Reports/DividendHistoryMonthYear',
  });
}

export function GetDividendsHistory(
  ProgramId,
  ClientSSNorTaxID,
  convertedDateValue,
  ShowInactiveAccounts
) {
  return axios.request({
    method: 'POST',
    url: baseApiUrl + 'Reports/DividendHistory',
    data: {
      ProgramId: ProgramId,
      ClientSSNorTaxID: ClientSSNorTaxID,
      convertedDateValue: convertedDateValue,
      ShowInactiveAccounts:ShowInactiveAccounts
    },
  });
}

export function GetHistoryHoldings(selectedYearMonth,accountSelectedValues)
{
    return axios.request({
      method: 'POST',
      url: baseApiUrl + 'Holdings/InvestmentTypeHoldings/History',
      data: {
          YearMonth: selectedYearMonth,
          AccountUniqueIDs: accountSelectedValues
          },
    });
}

export function GetAccountsForHistoryHoldings(ProgramID,SSNorTIN,YearMonth,ShowInactiveAccounts)
{
    return axios.request({
      method: 'POST',
      url: baseApiUrl + 'Holdings/History/Accounts',
      data: {
        ProgramID: ProgramID,
        SSNorTIN: SSNorTIN,
        YearMonth : YearMonth,
        ShowInactiveAccounts : ShowInactiveAccounts
          },
    });
}
















