import React from 'react';
import styled from 'styled-components';
import {
  theme,
  Text,
  TableComponent,
  AvatarComponent,
} from '@pfmam/pfmam';
import { Box, Container } from '../../../styles/globalStyle';
import moment from 'moment';
import { getNameInitials } from '../../../utils/common';
import NoDataFound from '../../../common/components/noDataFound';
import AccountActions from '../accountActions';

const Wrapper = styled.div`
  padding-top: 40px;
`;

const TeamMembersContainer = ({
  teamMembers,
  handleAction,
  handleMenuClick,
  isGlobalSettingsPage,
}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      align: 'left',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      className: 'sort-space-none',
      width: 364,
      render: (text, record) => (
        <Box display="flex" alignitems="flex-start">
          <AvatarComponent
            initials={record.profileImg ? null : getNameInitials(record)}
            src={record.profileImg}
            size={40}
            style={{
              color: `${theme.colors.white}`,
              backgroundColor: `${theme.colors.blue_primay}`,
              fontSize: `${theme.font_size.size_xl}`,
            }}
          />
          <Box padding="0 0 0 14px">
            <Text
              fontSize={`${theme.font_size.size_xl}`}
              color={`${theme.colors.black_tertiary}`}
              lineheight="24px"
              label={` ${record.firstName} ${record.lastName}`}
            />
            {record.email ? (
              <Text
                fontSize={`${theme.font_size.size_xl}`}
                color={`${theme.colors.grey_tertiary}`}
                lineheight="24px"
                label={record.email}
              />
            ) : null}
          </Box>
        </Box>
      ),
    },
    {
      title: 'Last Login (ET)',
      dataIndex: 'lastLoginDate',
      key: 'lastLoginDate',
      sorter: (a, b) =>{
        var aDate =a.lastLoginDate;
        var bDate= b.lastLoginDate;
        if(aDate===null)
        {
         aDate= moment("1900-01-01");
        }
        if(bDate===null)
        {
          bDate = moment("1900-01-01");
        }
        return moment(aDate).unix() - moment(bDate).unix();
        
      },
      className: 'sort-space-none',
      width: 300,
      render: (text) => (
        <Text
          label={
            text != null ? moment(text).format('MMM DD, YYYY HH:mm') : null
          }
        />
      ),
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      //sorter: true,
      className: 'sort-space-none',
      width: 496,
      // render: (text) =>
      //   text &&
      //   text.length &&
      //   text.map((permission) => {
      //     return permission.accounts.length > 0 ? (
      //       <>{permission.permission}</>
      //     ) : null;
      //   }),
    },
    {
      title: '',
      dataIndex: 'righticon',
      key: 'righticon',
      align: 'right',
      className: 'action-cell',
      render: (_, record) => (
        <AccountActions
          type="Team Member"
          record={record}
          handleMenuClick={handleMenuClick}
          isGlobalSettingsPage={isGlobalSettingsPage}
        />
      ),
    },
  ];
  return (
    <Container>
      <Wrapper>
        <TableComponent
          className="table-primary"
          title={
            <Box
              display="flex"
              justifycontent="space-between"
              alignitems="center"
              padding="24px 48px 24px 32px"
              border={`1px solid ${theme.colors.grey_fossil}`}
              borderwidth="0 0 1px 0"
            >
              <Text
                label="Team Member Access"
                fontSize={`${theme.font_size.size_3xl}`}
                lineheight="32px"
                color={`${theme.colors.black_tertiary}`}
              />
              {/* {isGlobalSettingsPage ? (
                <ButtonAnt
                  type="link"
                  label="Add Team Member"
                  icon={<PlusCircleOutlined />}
                  onClick={(e) => {
                    handleMenuClick(e, null, 'Team Member');
                  }}
                />
              ) : (
                <Link
                  to={{
                    pathname: '/settings',
                    accType: 'Team Members',
                  }}
                >
                  <ButtonAnt type="link" label="Manage Team Members" />
                </Link>
              )} */}
            </Box>
          }
          columns={columns}
          dataSource={teamMembers}
          locale={{
            emptyText: <NoDataFound text={teamMembers.length===null?"No team members":""}/>,
          }}
          showSorterTooltip={false}
          pagination={
            teamMembers.length > 10
              ? {
                  position: ['bottomRight'],
                  showQuickJumper: true,
                  size: 'default',
                }
              : false
          }
          onRow={(record) => ({
            onClick: () => {
              handleAction('Team Member', 'details', record);
            },
          })}
        />
      </Wrapper>
    </Container>
  );
};

export default TeamMembersContainer;
