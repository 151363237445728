import React from 'react';
import styled from 'styled-components';
import { theme, Text } from '@pfmam/pfmam';
import { Box } from '../../styles/globalStyle';

const Ul = styled.ul`
  list-style-type: disc;
  padding-left: 28px;
`;

const Li = styled.li`
  font-size: ${theme.font_size.size_xl};
  color: ${theme.colors.grey_secondary};
  line-height: 24px;
  margin-bottom: 10px;
`;

const LinkedText = styled.p`
  font-size: ${theme.font_size.size_xl};
  color: ${theme.colors.grey_secondary};
  line-height: 24px;
  margin-bottom: 10px;
`;

const TermsConditionsContent = ({ isAuthenticatedUser }) => {
  return (
    <Box padding="40px 0 0" width="100%" maxwidth="800px" margin="0 auto">
      <Box boxcolor={`${theme.colors.white}`} radius="2px">
        {isAuthenticatedUser && (
          <Box
            border={`1px solid ${theme.colors.grey}`}
            borderwidth="0 0 1px 0"
            padding="24px 32px"
          >
            <Text
              label="Terms and Conditions of Use"
              fontSize={`${theme.font_size.size_3xl}`}
              color={`${theme.colors.black_secondary}`}
              lineheight="32px"
            />
            <Text
              label="Effective Date: Sep 24, 2021"
              fontSize={`${theme.font_size.size_3xl}`}
              color={`${theme.colors.black_secondary}`}
              lineheight="32px"
            />
          </Box>
        )}
        <Box padding="43px 32px 48px">
          <Text
            label="Acceptance of Terms and Conditions of Use (“Terms of Use”)"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="The services that PFM Asset Management LLC (“PFMAM”) provides to you are subject to the following Terms of Use."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Read these Terms of Use carefully before using this system. Use of this system signifies that you have read, understand, and agree to abide by the Terms of Use. If you do not agree with these Terms of Use  you are required to immediately discontinue use of this site."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Changes to the Terms of Use"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="We may revise and update the Terms of Use, or any portion thereof, at any time in our sole discretion without notice to you.  All changes are effective immediately when we post them on the Website.  Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes.  You are expected to check this page regularly, so you are aware of any changes, as they are binding on you."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Accessing the Website and Account Security"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <LinkedText>
            To access the Website or some of the resources it offers, you may be
            asked to provide certain registration details or other information.
            It is a condition of your use of the Website that all the
            information you provide on the Website is correct, current and
            complete. You agree that all information you provide to register
            with this Website or otherwise, including but not limited to the use
            of any interactive features on the Website, is governed by our{' '}
            <a
              href={isAuthenticatedUser ? '/privacy-policy' : '/PrivacyPolicy'}
            >
              Privacy Policy
            </a>{' '}
            and you consent to all actions we take with respect to your
            information consistent with our Privacy Policy.
          </LinkedText>

          <Text
            label="Use of this Website requires a username and password that must be kept confidential and may not be used by persons who have not correctly completed and submitted an Online Access Registration Form. A username must be established for each person that desires access to this Website. Usernames may not be shared.  You agree to notify us immediately of any unauthorized access to your use of your username or password or any other breach of security.  You also agree to ensure that you exit from your account at the end of each session.  You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Copyrights/Trademarks/Restrictions on Use"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="This Website is owned and operated by PFMAM for the benefit of our investment advisory clients and participants in investment funds which we manage."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="The PFMAM name, logo(s) and all related names, logos, product and service names, designs and slogans are trademarks of PFMAM or its affiliates or licensors.  You must not use such marks without the prior written permission of PFMAM.  All other names, logos product and service names, designs and slogans on this Website are the trademarks of their respective owners."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Prohibited Uses"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="You may use the Website only for lawful purposes and in accordance with these Terms of Use.  You agree not to use the Website:"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Ul>
            <Li>
              In any way that violates any applicable federal, state, local or
              international law or regulation (including without limitation, any
              laws regarding the export of data or software to and from the
              United States or other countries).
            </Li>
            <Li>
              For the purposes of exploiting, harming or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information or
              otherwise
            </Li>
            <Li>
              To harvest or otherwise collect information about others,
              including email addresses
            </Li>
            <Li>
              To use, download or otherwise copy, or provide to a person or
              entity any directory of users of the Website, or other user or
              usage information or any portion thereof
            </Li>
            <Li>
              To send, knowingly, receive upload download use or reuse any
              material which does not comply with the Content Standards set out
              in the Terms and Conditions
            </Li>
            <Li>
              To transmit, or procure the sending of, any advertising or
              promotional materials, including any “junk mail”, “chain letters”
              or “spam” or any other similar solicitation
            </Li>
            <Li>
              To impersonate or attempt to impersonate PFMAM, any employee of
              PFMAM, another user or any other person or entity, including
              without limitation, by using email addresses or screen names
              associated with any of the foregoing.
            </Li>
            <Li>
              To engage in any other conduct that restricts or inhibits anyone’s
              use or enjoyment of the Website, or which, as determined by PFMAM
              in its sole discretion, may harm PFMAM or its users of the Website
              or expose any of them to liability.
            </Li>
          </Ul>
          <Text
            label="Additionally, you agree not to:"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 64px"
          />
          <Ul>
            <Li>
              Use the Website in any manner that could disable, overburden,
              damage, or impair the Website or interfere with any other party's
              use of the Website, including their ability to engage in real time
              activities through the Website.
            </Li>
            <Li>
              Use any robot, spider or other automatic device, process or means
              to access the Website for any purpose, including monitoring or
              copying any of the material on the Website.
            </Li>
            <Li>
              Use any manual process to monitor or copy any of the material on
              the Website for any other unauthorized purpose.
            </Li>
            <Li>
              Use any device, software or routine that interferes with the
              proper working of the Website.
            </Li>
            <Li>
              Introduce any viruses, trojan horses, worms, time bombs,
              cancelbots, corrupted files, logic bombs or other material which
              is malicious or technologically harmful.
            </Li>
            <Li>
              Attempt to gain unauthorized access to, interfere with, damage or
              disrupt any parts of the Website, the server on which the Website
              is stored, or any server, computer or database connected to the
              Website.
            </Li>
            <Li>
              Attack the Website via a denial-of-service attack or a distributed
              denial-of-service attack.
            </Li>
            <Li>
              Otherwise attempt to interfere with the proper working of the
              Website.
            </Li>
          </Ul>
          <Text
            label="Reliance on Information Posted"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="This Website may include content provided by third parties and we are not responsible or liable to you or any third party for the content or accuracy of any materials provide by any third party. This Website makes no recommendations to buy or sell any fund or security."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Limitations on Shareholder Transactions"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="Use of this system signifies that the user agrees to the following transaction limitations in addition to those found in the Fund Information Statement or Prospectus, as applicable:"
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Ul>
            <Li>
              Transactions initiated on this Website are not guaranteed and are
              subject to the final approval of the Fund/Program Administrator.
            </Li>
            <Li>
              All transaction requests will be reviewed and may be denied prior
              to and/or without any User notification.
            </Li>
            <Li>
              No withdrawals will be processed for amounts that exceed available
              account balances.
            </Li>
          </Ul>
          <Text
            label="We make no claims that the Website or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside the United States, you do so on your own initiative and are responsible for compliance with local laws."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />

          <Text
            label="You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our Website for any reconstruction of any lost data."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />

          <Text
            label="THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN AS IS AND AS AVAILABLE BASIS. PFMAM SHALL NOT BE LIABLE FOR, AND HEREBY DISCLAIMS ALL, WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />

          <Text
            label="THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />

          <Text
            label="Although it is our intention to provide accurate and reliable information, we do not guarantee and are not responsible for the accuracy, timeliness, completeness, or suitability for use of such information in this Website. We do not warrant that this Website will be free of system failures, nor do we guarantee that the service will be uninterrupted."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Limitation on Liability"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="32px 0 10px"
          />
          <Text
            label="IN NO EVENT WILL PFMAM, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 32px"
          />
          <Text
            label="Indemnification"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="You agree to defend, indemnify and hold PFMAM, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your User Contributions, any use of the Website's content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Website."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Governing Law and Jurisdiction"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="All matters relating to the Website and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule (whether of the State of Delaware or any other jurisdiction)."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Website shall be instituted exclusively in the United States District Court for the District Of Delaware or the courts of the State of Delaware located in the City of Wilmington and County of New Castle although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
          />
          <Text
            label="Waiver and Severability"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="No waiver by PFMAM of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of PFMAM to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision. "
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="Special Browser Settings"
            fontSize={`${theme.font_size.size_2xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="32px"
            margin="0 0 10px"
          />
          <Text
            label="It will be necessary to enable cookies on your web browser. In order to provide a more convenient web experience this Website utilizes “cookies.” The cookies created by this system are temporary, will not be saved to your hard drive, do not access data from your hard drive, and will not be used to collect personal data. If you have not enabled cookies on your browser you will be presented with a special settings screen that will describe how to enable cookies for most browsers."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
          <Text
            label="PFMAM reserves the right to terminate this Website or any users’ access to this Website if we believe the user(s) fails to comply with these Terms of Use."
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.grey_secondary}`}
            lineheight="24px"
            margin="0 0 10px"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TermsConditionsContent;
