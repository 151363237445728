import React from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'antd';
import { theme } from '@pfmam/pfmam';
// import CancelTransaction from './cancelTransaction';
import { CloseCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const ModalWrapper = styled(Modal)`
  .ant-btn {
    margin-top: 10px;
    height: 52px;
    width: 186px;
    padding: 5px;
  }
  .ant-btn-primary {
    background: ${theme.colors.danger_red};
  }
  .ant-modal-content {
    box-shadow: ${theme.shadows.modal};
    border: 1px solid ${theme.colors.grey_fossil};
    border-radius: 4px;
    .ant-modal-header {
      border-bottom-color: ${theme.colors.grey_fossil};
      padding: 24px 32px;
      .ant-modal-title {
        font-size: ${theme.font_size.size_3xl};
        line-height: 32px;
      }
    }
    .ant-modal-body {
      margin-left: 10px;
      border-bottom-color: none;
    }
    .ant-modal-footer {
      height: 100px;
    }
  }
`;

const UserLeaveConfirmation = (
  message,
  callback,
  confirmOpen,
  setConfirmOpen
) => {
  const container = document.createElement('div');

  container.setAttribute('custom-confirm-view', '');

  const handleConfirm = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
    setConfirmOpen(false);
  };

  const handleCancel = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    callback();
    setConfirmOpen(false);
  };

  document.body.appendChild(container);
  const { header, content } = JSON.parse(message);

  ReactDOM.render(
    <ModalWrapper
      visible={confirmOpen}
      title={<strong>{header}</strong>}
      onCancel={handleCancel}
      onOk={handleConfirm}
      okText="Discard Transaction"
      cancelText="Don't Discard"
      closeIcon={
        <CloseCircleOutlined
          style={{ fontSize: '28px', color: theme.colors.blue_primay }}
        />
      }
    >
      <p>{content}</p>
    </ModalWrapper>,

    // <Modal
    //   title={'Discard'}
    //   width={590}
    //   bodyStyle={{
    //     padding: '32px 32px 34px 32px',
    //   }}
    //   maskStyle={{
    //     background: '#17365d',
    //     opacity: 0.3,
    //   }}
    //   footer={null}
    //   visible={confirmOpen}
    //   onCancel={handleCancel}
    //   onOk={handleConfirm}
    //   //   content={
    //   //     <CancelTransaction
    //   //       handleCancel={handleCancel}
    //   //       handleConfirm={handleConfirm}
    //   //     />
    //   //   }
    // />,
    container
  );
};

export default UserLeaveConfirmation;
