/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, Switch, Space } from 'antd';
import {
  theme,
  Text,
  TableComponent,
  ButtonAnt,
  SelectComponent,
} from '@pfmam/pfmam';
import { Box, Container } from '../../styles/globalStyle';
import notificationsPreferences from '../../data/profileNotifications.json';
import enablenotificationsPreferences from '../../data/EnablenotificationsPreferences.json';
import CircleCheck from '../../assets/images/circle-check.png';
import { EditOutlined, CaretDownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {CheckClientSSNIsNumeric} from '../../utils/numberFormat'
import {
  getNotificationPreferences,
  UpdateNotificationPreferences,
  UpdateNotificationDeliveryOptions,
} from '../../actions/actions';
const Wrapper = styled.div`
  padding-top: 32px;
`;
const ExpandButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  min-width: 32px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: -2px;
  & > span {
    height: 19px;
    min-width: 19px;
    width: 19px;
    margin: 0 auto;
    border: 2px solid ${theme.colors.grey};
    border-radius: 2px;
    padding: 5px;
    position: relative;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      top: 3px;
      background: ${theme.colors.black_secondary};
      height: 10px;
      width: 2px;
      transition: transform 0.3s ease-out;
      transform: rotate(0deg);
    }
    &::after {
      content: '';
      position: absolute;
      top: 7px;
      right: 2.3px;
      background: ${theme.colors.black_secondary};
      height: 2px;
      width: 10px;
      transition: transform 0.3s ease-out;
      transform: rotate(-180deg);
    }
  }
  &:focus {
    & > span {
      box-shadow: ${theme.shadows.input_focus};
      border-color: ${theme.colors.blue_primay};
    }
  }
  &.expanded {
    & > span {
      &::after {
        transform: rotate(0deg);
      }
      &::before {
        transform: rotate(90deg);
      }
    }
  }
`;
const StatementSelect = styled.span`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 52px;
    padding: 0 12px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 52px;
    font-size: ${theme.font_size.size_xl};
    color: ${theme.colors.black_secondary} !important;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: ${theme.colors.grey};
    box-shadow: none;
  }
`;
const SpaceWrapper = styled(Space)`
  .ant-btn {
    height: 40px;
    padding: 6px 16px;
  }
`;
function checkIndividualAccountToggleStatus(record, parentData) {
  let status = null;
  switch (parentData.title) {
    case 'Transactions':
      if (record.transactionPermission) {
        status = true;
      } 
      else if(!record.transactionPermission && record.viewReportsPermission && record.canTransact)
      {
        status=true;
      }
      else
      {
        status=false;
      }
      break;
    case 'Banking Instructions':
      if (record.bankingInstrPermission) {
        status = true;
      } 
      else if(!record.bankingInstrPermission && record.viewReportsPermission && record.canBankingInstr)
      {
        status=true;
      }
      else
      {
        status=false;
      }
     break;
    case 'Check Verification':
      if (record.checkVerifierPermission) {
        status = true;
      } 
      else
      {
        status=false;
      }
      break;
      case 'Check Signer':
        if (record.checkSignerPermission) {
          status = true;
        } 
        else
        {
          status=false;
        }
        break;
    default:
      status = false;
  }
  return status;
}
function checkIndividualAccountDisabledStatus(record, parentData) {
  let status = null;
  switch (parentData.title) {
    case 'Transactions':
      if (record.viewReportsPermission && record.transactionPermission) {
        status = true;
      } else if (
        record.viewReportsPermission &&
        record.transactionPermission &&
        record.canTransact
      ) {
        status = true;
      } else if (record.viewReportsPermission) {
        status = false;
      }
      break;
    case 'Banking Instructions':
      if (record.viewReportsPermission && record.bankingInstrPermission) {
        status = true;
      } else if (
        record.viewReportsPermission &&
        record.bankingInstrPermission &&
        record.canBankingInstr
      ) {
        status = true;
      } else if (record.viewReportsPermission) {
        status = false;
      }
      break;
    case 'Check Verification':
      status=true;
      break;
      case 'Check Signer':
        status=true;
        break;
    default:
      status = false;
  }
  return status;
}

function checkAllAccountStatus(accounts, title) {
  let status = null;
  let permissionValue = null;
  switch (title) {
    case 'Transactions':
      permissionValue = accounts.filter(function (item) {
        if (
          (item.viewReportsPermission && item.transactionPermission) ||
          item.canTransact
        ) {
          return true;
        }
      });
      status = permissionValue.length == accounts.length ? true : false;
      break;
    case 'Banking Instructions':
      permissionValue = accounts.filter(function (item) {
        if (
          (item.viewReportsPermission && item.bankingInstrPermission) ||
          item.canBankingInstr
        ) {
          return true;
        }
      });
      status = permissionValue.length == accounts.length ? true : false;
      break;
    case 'Permission Changes':
      permissionValue = accounts.filter((x) => x.permission == true);
      status = permissionValue.length == accounts.length ? true : false;
      break;
    case 'Document Upload':
      permissionValue = accounts.filter((x) => x.candocUpload == true);
      status = permissionValue.length == accounts.length ? true : false;
      break;
    case 'Maturity':
      permissionValue = accounts.filter((x) => x.isMaturity == true);
      status = permissionValue.length == accounts.length ? true : false;
      break;
    case 'Check Verification':;
      permissionValue = accounts.filter((x) => x.checkVerifierPermission == true);
      status = permissionValue.length == accounts.length ? true : false;
      break;
    case 'Check Signer':
      permissionValue = accounts.filter((x) => x.checkSignerPermission == true);
      status = permissionValue.length == accounts.length ? true : false;
      break;
    default:
      status = false;
  }
  return status;
}

function checkAllAccountDisabledStatus(accounts, title) {
  let status = null;
  let permissionValue = null;
  switch (title) {
    case 'Transactions':
      permissionValue = accounts.filter(function (item) {
        if (
          (item.viewReportsPermission &&
            item.transactionPermission == true &&
            item.canTransact == false) ||
          (item.viewReportsPermission &&
            item.transactionPermission == true &&
            item.canTransact == true)
        ) {
          return true;
        }
      });
      status =
        permissionValue.length > 0 && accounts.length === permissionValue.length
          ? true
          : false;
      break;
    case 'Banking Instructions':
      permissionValue = accounts.filter(function (item) {
        if (
          (item.viewReportsPermission &&
            item.bankingInstrPermission == true &&
            item.canBankingInstr == false) ||
          (item.viewReportsPermission &&
            item.bankingInstrPermission == true &&
            item.canBankingInstr == true)
        ) {
          return true;
        }
      });
      status = permissionValue.length === accounts.length ? true : false;
      break;
    case 'Check Verification':
      status=true;
      break;
      case 'Check Signer':
      status=true;
      break;
    default:
      status = false;
  }
  return status;
}

const NotificationsComponent = () => {
  // The user state can be fetched from the reducer directly
  const [userPrivilege, setUserPrivilege] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [preferences, setPreferences] = useState(null);
  const [statementPreferences, setstatementPreferences] = useState([]);
  const [editStatementDelivery, setEditStatementDelivery] = useState(null);
  const [statementDeliveryValue, setStatementDeliveryValue] =
    useState('paper_statements');
  const [hideInAppStatus, setHideInAppStatus] = useState(false);
  const enableCheckNotificationForFund = useSelector(
    (state) => state.siteInfo.enableCheckNotificationForFund
  );
  const contactId = useSelector((state) => state.userSlice.user.profile.sub);
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const ssnorTIN = clientDetails.payload[0].ssnortin;
  const showMoveFunds = useSelector(
    (state) => state.siteInfo.allowOnlineTransact
  );
  const poolHybridType=CheckClientSSNIsNumeric(ssnorTIN);
  console.log(ssnorTIN);
  console.log(poolHybridType);
  useEffect(() => {
    getNotificationPreferences(contactId, ssnorTIN)
      .then((response) => {
        setUserPrivilege(response.data.isStatementRecipient);
        setAccounts(response.data.contactNotificationPreferenceResponses);
        if (response.data.statementNotify.statemenDeliveryPreference) {
          setStatementDeliveryValue('electronic_statements');
        }
        setstatementPreferences(response.data.statementNotify);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (!enableCheckNotificationForFund) {
      if(!poolHybridType){
        //remove following from notification preferences
        let keysToRemove=["canBankingInstr","canTransact","permission","isMaturity","isNotifiedOfDailies"];
        
        let notificationsPreferencesforNonPoolHybrid=enablenotificationsPreferences.filter(obj=> !keysToRemove.includes(obj.key));
        // console.log(notificationsPreferencesforNonPoolHybrid);
        setPreferences([...notificationsPreferencesforNonPoolHybrid]);
      }
      else{
        // console.log(enablenotificationsPreferences);
        setPreferences([...enablenotificationsPreferences]);
      } 
    } else {
      if(!poolHybridType){
        //remove following from notification preferences
        let keysToRemove=["canBankingInstr","canTransact","permission","isNotifiedOfDailies","isCheckVerifier","isCheckSigner"];
        let notificationsPreferencesforNonPoolHybrid=notificationsPreferences.filter(obj=> !keysToRemove.includes(obj.key));
        // console.log(notificationsPreferencesforNonPoolHybrid);
        setPreferences([...notificationsPreferencesforNonPoolHybrid]);
      }
      else{
      // console.log(notificationsPreferences);
      setPreferences([...notificationsPreferences]);
      } 
    }
    setHideInAppStatus(true);
  }, [enableCheckNotificationForFund]);

  // const updateAccounts = (checked, accArray, singleAccount) => {
  //   if (checked) {
  //     const acc = accArray && accArray.length > 0 ? accArray : [];
  //     acc.push(singleAccount);

  //     return [...acc];
  //   } else {
  //     const filteredData = accArray.filter((acc) => acc !== singleAccount);
  //     return [...filteredData];
  //   }
  // };
  // const handleToggleChange = (checked, record, type, singleAccount = null) => {
  //   const pref = preferences;
  //   let index =
  //     pref && pref.findIndex((preference) => preference.key === record.key);
  //   if (type === 'inApp') {
  //     if (singleAccount) {
  //       pref[index].inAppAccounts = updateAccounts(
  //         checked,
  //         pref[index].inAppAccounts,
  //         singleAccount
  //       );
  //       pref[index].allAccountsInAppStatus =
  //         pref[index].inAppAccounts && pref[index].inAppAccounts.length > 0;
  //     } else {
  //       pref[index].allAccountsInAppStatus = checked;
  //       pref[index].inAppAccounts = checked ? [...allAccounts] : null;
  //     }
  //   } else {
  //     if (singleAccount) {
  //       pref[index].emailAccounts = updateAccounts(
  //         checked,
  //         pref[index].emailAccounts,
  //         singleAccount
  //       );
  //       pref[index].allAccountsEmailStatus =
  //         pref[index].emailAccounts && pref[index].emailAccounts.length > 0;
  //     } else {
  //       pref[index].allAccountsEmailStatus = checked;
  //       pref[index].emailAccounts = checked ? [...allAccounts] : null;
  //     }
  //   }
  //   setPreferences([...pref]);
  // };

  const handleToggleChange = (checked, record, updateKey) => {
    if (updateKey == 'allAccountsEmailStatus') {
      let updateStatus = [];
      if (
        record.title == 'Permission Changes' ||
        record.title == 'Document Upload' ||
        record.title == 'Maturity' ||
        record.title == 'Check Signer'
      ) {
        accounts.forEach((item) => {
          item[`${record.key}`] = checked;
          updateStatus.push(item);
        });
      } else {
        accounts.forEach((item) => {
          if (
            record.title == 'Transactions' ||
            record.title == 'Check Verification' ||
            record.title == 'Banking Instructions'
          ) {
            item[`${record.key}`] = checked;
            updateStatus.push(item);
          }
        });
      }
      UpdateNotificationPreferences(updateStatus, ssnorTIN, contactId).then(
        (response) => {
          if (response.data.contactNotificationPreferenceResponses.length > 0) {
            setAccounts(response.data.contactNotificationPreferenceResponses);
            setstatementPreferences(response.data.statementNotify);
          }
        }
      );
      console.log('changed data ', updateStatus);
    } else {
      if (
        record.title == 'Monthly Statement Email' ||
        record.title == 'Daily Confirmation Email'
      ) {
        UpdateNotificationDeliveryOptions(
          contactId,
          ssnorTIN,
          checked,
          record.title
        ).then((response) => {
          if (response.data) {
            setAccounts(response.data.contactNotificationPreferenceResponses);
            setstatementPreferences(response.data.statementNotify);
          }
        });
      } else {
        const filter = accounts.filter(
          (acc) => acc.accountUniqueId === record.accountUniqueId
        );
        console.log('filter data ', filter);
        filter[0][`${updateKey}`] = checked;
        let index = accounts.findIndex(
          (acc) => acc.accountUniqueId === record.accountUniqueId
        );
        accounts[index] = filter[0];
        UpdateNotificationPreferences(filter, ssnorTIN, contactId).then(
          (response) => {
            if (response.data) {
              setAccounts(response.data.contactNotificationPreferenceResponses);
              setstatementPreferences(response.data.statementNotify);
            }
          }
        );
        console.log('changed data ', filter);
      }
    }
  };
  const getColumns = (accounts, title) => {
    const allAccountEmailStatus =
      accounts && accounts.length > 0
        ? checkAllAccountStatus(accounts, title)
        : false;
    const allAccountDisabledStatus =
      accounts && accounts.length > 0
        ? checkAllAccountDisabledStatus(accounts, title)
        : false;
    const columns = [
      {
        title: '',
        dataIndex: '',
        key: 'accounts',
        align: 'left',
        width: 665,
        render: (_, record) => (
          <Text
            label={
              record.title === 'Monthly Statement Email' ||
              record.title === 'Daily Confirmation Email'
                ? ''
                : 'All Accounts'
            }
            lineheight="20px"
            margin="4px 0 0"
          />
        ),
      },
      {
        title: '',
        dataIndex: 'allAccountsInAppStatus',
        key: 'allAccountsInAppStatus',
        align: 'left',
        width: 235,
        render: (text, record) => (
          <Box display="inline-flex" alignitems="center">
            <Switch
              checked={text}
              onChange={(checked) =>
                handleToggleChange(checked, record, 'inApp')
              }
            />
            <Text
              label={'In App'}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
              color={`${theme.colors.black_secondary}`}
              margin="0 0 0 16px"
            />
          </Box>
        ),
      },
      {
        title: '',
        dataIndex: 'allAccountsEmailStatus',
        key: 'allAccountsEmailStatus',
        align: 'left',
        width: 235,
        render: (text, record) => (
          <Box display="inline-flex" alignitems="center">
            <Switch
              checked={allAccountEmailStatus}
              onChange={(checked) =>
                handleToggleChange(checked, record, 'allAccountsEmailStatus')
              }
              disabled={allAccountDisabledStatus}
            />
            <Text
              label={'Email'}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
              color={`${theme.colors.black_secondary}`}
              margin="0 0 0 16px"
            />
          </Box>
        ),
      },
    ];
    if (hideInAppStatus) {
      columns.splice(1, 1);
    }
    return columns;
  };

  const getStatementColumns = (statementPreferences, title) => {
    let status = false;
    if (statementPreferences && statementPreferences != null) {
      if (title == 'Monthly Statement Email') {
        status = statementPreferences.isNotifiedOfStatements;
      } else {
        status = statementPreferences.isNotifiedOfDailies;
      }
    }
    const columns = [
      {
        title: '',
        dataIndex: '',
        key: 'accounts',
        align: 'left',
        width: 665,
        render: (_, record) => (
          <Text
            label={
              record.title === 'Monthly Statement Email' ||
              record.title === 'Daily Confirmation Email'
                ? ''
                : 'All Accounts'
            }
            lineheight="20px"
            margin="4px 0 0"
          />
        ),
      },
      {
        title: '',
        dataIndex: 'allAccountsInAppStatus',
        key: 'allAccountsInAppStatus',
        align: 'left',
        width: 235,
        render: (text, record) => (
          <Box display="inline-flex" alignitems="center">
            <Switch
              checked={text}
              onChange={(checked) =>
                handleToggleChange(checked, record, 'inApp')
              }
            />
            <Text
              label={'In App'}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
              color={`${theme.colors.black_secondary}`}
              margin="0 0 0 16px"
            />
          </Box>
        ),
      },
      {
        title: '',
        dataIndex: 'allAccountsEmailStatus',
        key: 'allAccountsEmailStatus',
        align: 'left',
        width: 235,
        render: (text, record) => (
          <Box display="inline-flex" alignitems="center">
            <Switch
              checked={status}
              onChange={(checked) =>
                handleToggleChange(checked, record, 'statementPreferences')
              }
              disabled={false}
            />
            <Text
              label={'Email'}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
              color={`${theme.colors.black_secondary}`}
              margin="0 0 0 16px"
            />
          </Box>
        ),
      },
    ];
    if (hideInAppStatus) {
      columns.splice(1, 1);
    }
    return columns;
  };
  const getChildColumns = (parentData) => {
    const childColumns = [
      {
        title: '',
        dataIndex: '',
        key: '',
        width: 80,
      },
      {
        title: '',
        dataIndex: 'accountDisplayName',
        key: 'accountDisplayName',
        width: 665,
      },
      {
        title: '',
        dataIndex: '',
        key: 'childInAppStatus',
        align: 'left',
        width: 235,
        render: (_, record, text) => (
          <Box display="inline-flex" alignitems="center">
            <Switch
              checked={
                parentData.inAppAccounts &&
                parentData.inAppAccounts.includes(record.accName)
              }
              onChange={(checked) =>
                handleToggleChange(checked, parentData, 'inApp', record.accName)
              }
            />
            <Text
              label={'In App'}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
              color={`${theme.colors.black_secondary}`}
              margin="0 0 0 16px"
            />
          </Box>
        ),
      },
      {
        title: '',
        dataIndex: '',
        key: 'childEmailStatus',
        align: 'left',
        width: 235,
        render: (_, record, text) => (
          <Box display="inline-flex" alignitems="center">
            <Switch
              // checked={record[parentData.key]||record[parentData.permission]}
              checked={
                parentData.title === 'Transactions' ||
                parentData.title === 'Banking Instructions' ||
                parentData.title === 'Check Verification' ||
                parentData.title === 'Check Signer'?
                checkIndividualAccountToggleStatus(record,parentData):record[parentData.key]}
              onChange={(checked) =>
                handleToggleChange(checked, record, parentData.key)
              }
              disabled={
                parentData.title === 'Transactions' ||
                parentData.title === 'Banking Instructions' ||
                parentData.title === 'Check Verification' ||
                parentData.title === 'Check Signer'
                  ? checkIndividualAccountDisabledStatus(record, parentData)
                  : false
              }
            />
            <Text
              label={'Email'}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
              color={`${theme.colors.black_secondary}`}
              margin="0 0 0 16px"
            />
          </Box>
        ),
      },
    ];
    if (hideInAppStatus) {
      childColumns.splice(2, 1);
    }
    return childColumns;
  };

  const expandable = {
    expandedRowRender: (record) => (
      <Table
        showHeader={false}
        columns={getChildColumns(record)}
        dataSource={accounts}
        pagination={false}
      />
    ),
    expandIcon: ({ expanded, onExpand, record }) => (
      <>
        {record.title === 'Monthly Statement Email' ||
        record.title === 'Daily Confirmation Email' ? (
          <Box width="50px" />
        ) : (
          <ExpandButton
            className={expanded ? 'expanded' : ''}
            onClick={(e) => {
              e.stopPropagation();
              onExpand(record, e);
            }}
          >
            <span></span>
          </ExpandButton>
        )}
      </>
    ),
  };

  const updateStatementDeliveryPreferences = () => {
    if (editStatementDelivery) {
      let Check = editStatementDelivery == 'paper_statements' ? false : true;
      UpdateNotificationDeliveryOptions(
        contactId,
        ssnorTIN,
        Check,
        'Statement Delivery'
      ).then((response) => {
        if (response.data) {
          setAccounts(response.data.contactNotificationPreferenceResponses);
          setstatementPreferences(response.data.statementNotify);
          setStatementDeliveryValue(editStatementDelivery);
          setEditStatementDelivery(null);
        }
      });
    }
  };

  const RenderDeliveryPreferences = () => {
    return (
      <Box
        boxcolor={`${theme.colors.white}`}
        margin="0 0 24px"
        border={`1px solid ${theme.colors.grey}`}
        borderwidth="0 0 1px 0"
      >
        <Text
          label="Statement Delivery Preferences"
          fontSize={`${theme.font_size.size_3xl}`}
          lineheight="32px"
          color={`${theme.colors.black_tertiary}`}
          padding="24px 32px 0"
        />
        <Text
          label="Choose statement delivery preference below, this can be updated at any time."
          fontSize={`${theme.font_size.size_xl}`}
          lineheight="24px"
          color={`${theme.colors.grey_tertiary}`}
          padding="4px 32px 0"
        />
        <Box
          border={`1px solid ${theme.colors.grey}`}
          borderwidth="1px 0 0 0"
          padding="18px 32px"
          margin="25px 0 0"
        >
          {editStatementDelivery ? (
            <Box display="flex" justifycontent="space-between">
              <StatementSelect>
                <SelectComponent
                  defaultValue="paper_statements"
                  value={editStatementDelivery || statementDeliveryValue}
                  option={[
                    {
                      label: 'Paper Statements',
                      value: 'paper_statements',
                    },
                    {
                      label: 'Electronic Statements',
                      value: 'electronic_statements',
                    },
                  ]}
                  onChange={(value) => setEditStatementDelivery(value)}
                  placeholder="Select"
                  suffixIcon={<CaretDownOutlined />}
                  type="secondary"
                  bordered="true"
                  dropdownClassName="statement-dropdown"
                />
              </StatementSelect>
              <SpaceWrapper size={16}>
                <ButtonAnt
                  icon={false}
                  label="Cancel"
                  type="secondary"
                  onClick={() => setEditStatementDelivery(null)}
                />
                <ButtonAnt
                  icon={false}
                  label="Update Preferences"
                  type="primary"
                  onClick={() => updateStatementDeliveryPreferences()}
                />
              </SpaceWrapper>
            </Box>
          ) : (
            <Box display="flex" justifycontent="space-between">
              <Box display="inline-flex" alignitems="center">
                <img src={CircleCheck} alt="circle check" />
                <Text
                  label={
                    statementDeliveryValue === 'paper_statements'
                      ? 'Paper Statements'
                      : 'Electronic Statements'
                  }
                  fontSize={`${theme.font_size.size_xl}`}
                  lineheight="24px"
                  color={`${theme.colors.black_secondary}`}
                  margin="0 0 0 17px"
                />
              </Box>
              <ButtonAnt
                icon={<EditOutlined />}
                label={false}
                type="link"
                onClick={() => setEditStatementDelivery(statementDeliveryValue)}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Container>
      <Wrapper>
        {userPrivilege ? <RenderDeliveryPreferences /> : null}
        {preferences &&
          preferences.map((preference) => {
            return (
              <Box margin="24px 0">
                <TableComponent
                  className="table-primary"
                  title={
                    <Box
                      justifycontent="space-between"
                      alignitems="center"
                      padding="24px 48px 24px 32px"
                      border={`1px solid ${theme.colors.grey_fossil}`}
                      borderwidth="0 0 1px 0"
                    >
                      <Text
                        label={preference.title}
                        fontSize={`${theme.font_size.size_3xl}`}
                        lineheight="32px"
                        color={`${theme.colors.black_tertiary}`}
                      />
                      <Text
                        label={preference.subtitle}
                        fontSize={`${theme.font_size.size_xl}`}
                        lineheight="32px"
                        color={`${theme.colors.grey_tertiary}`}
                      />
                    </Box>
                  }
                  showHeader={false}
                  expandable={
                    preference.title === 'Monthly Statement Email' ||
                    preference.title === 'Daily Confirmation Email'
                      ? expandable
                      : expandable
                  }
                  columns={
                    preference.title === 'Monthly Statement Email' ||
                    preference.title === 'Daily Confirmation Email'
                      ? getStatementColumns(
                          statementPreferences,
                          preference.title
                        )
                      : getColumns(accounts, preference.title)
                  }
                  dataSource={[preference]}
                />
              </Box>
            );
          })}
      </Wrapper>
    </Container>
  );
};

export default NotificationsComponent;

