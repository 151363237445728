/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Form } from 'antd';
import {
  theme,
  CardAnt,
  ButtonAnt,
  InputComponent,
  SelectComponent,
} from '@pfmam/pfmam';
import { Container, Box } from '../../styles/globalStyle';
import { CaretDownOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { getContactProfile, updateProfileContact } from '../../actions/actions';
import { useSelector } from 'react-redux';
import phoneFormatter from 'phone-formatter';
import { MaskedInput } from 'antd-mask-input';

const ButtonWrapper = styled.div`
  .anticon-check-circle {
    margin-left: 30px;
    margin-top: 2px;
  }
`;
const OverrideStyle = createGlobalStyle`
  .ant-form-item-control-input-content {
    & > span {
      width: 100%;
    }
    .primary.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 52px;
        width: 100%;
        background: ${theme.colors.white};
        color: ${theme.colors.black_secondary};
        border: 1px solid ${theme.colors.grey};
        padding: 14px 20px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 20px;
        }
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-arrow {
        font-size: ${theme.font_size.size_l};
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: ${theme.shadows.input_focus};
          border-color: ${theme.colors.blue_primay};
        }
          .ant-select-arrow {
          color: ${theme.colors.black_secondary};
        }
      } 
    }
  }
  
  .form-select-dropdown {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 16px;
          line-height: 16px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 40px;
        line-height: 24px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_xl};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
    }
  }
`;

const Wrapper = styled.div`
  padding-top: 32px;
`;

const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;

function modifyContactProfile(profile) {
  var contactProfile = [];
  contactProfile.push({
    ext: profile.phoneAddionalInfo,
    mvarEmailAddress: profile.emailAddress,
    mvarFaxNumber:
      profile.faxNumber != ''
        ? phoneFormatter.format(profile.faxNumber, '(NNN) NNN-NNNN')
        : null,
    mvarPhoneNumber:
      profile.phoneNumber != ''
        ? phoneFormatter.format(profile.phoneNumber, '(NNN) NNN-NNNN')
        : null,
    mvarMobileNumber:
      profile.mobileNumber != ''
        ? phoneFormatter.format(profile.mobileNumber, '(NNN) NNN-NNNN')
        : null,
    mvarSalutation: profile.salutation,
    mvarSecretAnswer: profile.secretAnswer,
    mvarSecretQuestion: profile.secretQuestion,
    mvarTitle: profile.title,
    mvarFirstName: profile.firstName,
    mvarLastName: profile.lastName,
    mvarLoginName: profile.loginName,
  });
  return contactProfile;
}
const RenderProfileForm = () => {
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const contactId = useSelector((state) => state.userSlice.user.profile.sub);
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  getContactProfile()
    .then((response) => {
      if (response.data != null) {
        const profile = modifyContactProfile(response.data);
        form.setFieldsValue(profile[0]);
      }
    })
    .catch((error) => {
      console.log(error);
    });

  // useEffect(() => {
  //   form.setFieldsValue({ ...user });
  // }, [user]);
  const onSubmit = (values) => {
    updateProfileContact(
      contactId,
      values.ext,
      values.mvarEmailAddress,
      values.mvarFaxNumber,
      values.mvarMobileNumber,
      values.mvarPhoneNumber,
      values.mvarSalutation,
      values.mvarSecretAnswer,
      values.mvarSecretQuestion,
      values.mvarTitle,
      values.mvarFirstName,
      values.mvarLastName,
      values.mvarLoginName
    )
      .then((response) => {
        if (response.data != null) {
          setSubmitted(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      onFinish={onSubmit}
      requiredMark={false}
      className="forms"
      onFieldsChange={() => setSubmitted(false)}
    >
      <Row gutter={80}>
        <Col span={12}>
          <Form.Item label="Login ID" name="mvarLoginName">
            <InputComponent type="default" disabled={true} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Title" name="mvarTitle">
            <InputComponent type="default" placeholder="Add Title" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={12}>
          <Form.Item label="Salutation" name="mvarSalutation">
            <SelectComponent
              option={[
                {
                  label: 'Dr.',
                  value: 'Dr',
                },
                {
                  label: 'Miss.',
                  value: 'Miss',
                },
                {
                  label: 'Mr.',
                  value: 'Mr',
                },
                {
                  label: 'Mrs.',
                  value: 'Mrs',
                },
                {
                  label: 'Ms.',
                  value: 'Ms',
                },
              ]}
              placeholder="Select Salutation"
              suffixIcon={<CaretDownOutlined />}
              dropdownClassName="form-select-dropdown"
              bordered={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Email Address"
            name="mvarEmailAddress"
            rules={[
              {
                required: true,
                message: 'Email Address Required',
              },
            ]}
          >
            <InputComponent type="default" placeholder="Add Email Address" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="First Name" name="mvarFirstName">
                <InputComponent
                  type="default"
                  placeholder="Add First Name"
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name" name="mvarLastName">
                <InputComponent
                  type="default"
                  placeholder="Add Last Name"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={15}>
              <Form.Item label="Phone Number" name="mvarPhoneNumber">
                <MaskedInput
                  mask="(111) 111-1111"
                  placeholder="(000) 000-0000"
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item label="Ext" name="ext">
                <InputComponent type="default" placeholder="000" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={12}>
          <Form.Item
            label="Secret Question"
            name="mvarSecretQuestion"
            rules={[
              {
                required: true,
                message: 'Secret Question Required',
              },
            ]}
          >
            <InputComponent
              type="default"
              placeholder="Enter Secret Questions"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Mobile Number" name="mvarMobileNumber">
            <MaskedInput mask="(111) 111-1111" placeholder="(000) 000-0000" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={12}>
          <Form.Item
            label="Secret Answer"
            name="mvarSecretAnswer"
            rules={[
              {
                required: true,
                message: 'Secret Answer Required',
              },
            ]}
          >
            <InputComponent type="default" placeholder="Add Secret Answer" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Fax Number" name="mvarFaxNumber">
            <MaskedInput mask="(111) 111-1111" placeholder="(000) 000-0000" />
          </Form.Item>
        </Col>
      </Row>
      <Box display="flex" justifycontent="flex-end">
        {submitted === false ? (
          <OverrideButtonAnt
            label="Save Changes"
            type="primary"
            htmlType="submit"
            linkTextColor={primary_color}
          />
        ) : (
          <ButtonWrapper>
            <ButtonAnt label="" type="success" icon={<CheckCircleOutlined />} />
          </ButtonWrapper>
        )}
      </Box>
    </Form>
  );
};
const ProfileComponent = () => {
  return (
    <Wrapper>
      <OverrideStyle />
      <Container>
        <CardAnt
          cardContent={<RenderProfileForm />}
          className="profile-card basic-card"
          title="Profile Information"
        />
      </Container>
    </Wrapper>
  );
};

export default ProfileComponent;
