/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Layout } from 'antd';
import { theme, Text, Tabs, Modal } from '@pfmam/pfmam';
import { Container, Box, Banner, Anchor } from '../styles/globalStyle';
import AppLayout from '../common/layouts/appLayout';
import { ArrowLeftOutlined } from '@ant-design/icons';
import accountDetails from '../data/AccountDetails.json';
import settings from '../data/dashboardSettings.json';
import AchAccountsContainer from './components/achAccounts/achAccountsContainer';
import WireAccountsContainer from './components/wireAccounts/wireAccountsContainer';
import PeerAccountsContainer from './components/peerAccounts/peerAccountsContainer';
import TeamMembersContainer from './components/teamMembers/teamMembersContainer';
import DetailsModalContent from './components/modals/viewAccountModal';
import RemoveAccountModal from './components/modals/removeAccountModal';
import { useSelector } from 'react-redux';
import {
  GetACHPayeeInstructions,
  GetClientTeamManagementInfo,
  GetWirePayeeInstructions,
  GetP2PInstructions,
  GetP2PInstructionsBySSNORTIN,
  GetTeamMemberDetails,
  GetTeamMemberDetailsByAccount,
  GetClientLevelTransBankingInstPermission,
  GetClientDetails,
} from '../actions/actions';
import { useHistory } from 'react-router-dom';
import MemberDetails from './components/teamMembers/memberDetails';
import AddEditAccounts from './components/addEditAccounts';
import OrganisationalDetails from './components/orgDetails';

const { Content } = Layout;

export const OverrideStyles = createGlobalStyle`
  .action-cell {
    .button-dropdown {
      .ant-btn {
        background: ${theme.colors.transparent};
      }
      &.ant-dropdown-open {
        .ant-btn {
          background: ${theme.colors.light_grey};
        }
      }
    }
  }
  .ant-dropdown-menu {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    padding: 4px 0;
    .ant-dropdown-menu-item {
      font-size: ${theme.font_size.size_l};
      line-height: 20px;
      color: ${theme.colors.black_secondary};
      padding: 8px 12px;
      &:hover {
        background: ${theme.colors.light_grey};
      }
    }
  }
  .ant-form-item-control-input-content {
    & > span {
      width: 100%;
    }
    .primary.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 52px;
        width: 100%;
        background: ${theme.colors.white};
        color: ${theme.colors.black_secondary};
        border: 1px solid ${theme.colors.grey};
        padding: 14px 20px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 20px;
        }
        .ant-select-selection-search-input {
          height: 100%;
        }
      }
      .ant-select-arrow {
        color: ${theme.colors.grey_stone};
        font-size: ${theme.font_size.size_l};
        margin-top: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: ${theme.shadows.input_focus};
          border-color: ${theme.colors.blue_primay};
        }
          .ant-select-arrow {
          color: ${theme.colors.black_secondary};
        }
       
      } 
    }
  }
  .form-select-dropdown {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      .ant-select-item {
        &.ant-select-item-group {
          color: ${theme.colors.grey_tertiary};
          min-height: 16px;
          line-height: 16px;
          padding: 6px 12px;
          &:hover {
            background: none;
          }
        }
      }
      .ant-select-item-option-selected,
      .ant-select-item-option-active {
        &:not(.ant-select-item-option-disabled) {
          background: ${theme.colors.white};
          font-weight: normal;
        }
      }
      .ant-select-item {
        min-height: 40px;
        line-height: 24px;
        padding: 8px 12px;
        font-size: ${theme.font_size.size_xl};
        color: ${theme.colors.black_secondary};
        font-family: 'Inter';
        &:hover {
        background: ${theme.colors.light_grey};
        font-family: 'Inter';
      }
    }
  }
  .rc-virtual-list-scrollbar-thumb {
    background: rgb(0, 0, 0, 0.2) !important;
  }
  .ant-form-item-has-error {
    .ant-form-item-control-input-content {
      .primary {
        &.ant-select-single:not(.ant-select-customize-input) {
          &.ant-select-focused {
            .ant-select-selector {
              box-shadow: 0px 0px 0px 2px rgba(186, 90, 78, 0.2);
              border-color: ${theme.colors.pie_red};
            }
          } 
          .ant-select-selector {
            border-color: ${theme.colors.pie_red} !important;
          }
        }
      }
    }
    
  } 
`;

const ContentWrapper = styled(Content)`
  .ant-table {
    border-radius: 4px;
    .ant-table-tbody {
      & > tr {
        cursor: pointer;
        &:hover {
          &.ant-table-row:not(.ant-table-expanded-row) {
            background: linear-gradient(
                0deg,
                ${(props) => props.rgbaHover},
                ${(props) => props.rgbaHover}
              ),
              ${theme.colors.white};
          }
        }
        & > td {
          vertical-align: top;
          padding: 20px 32px;
          &.ant-table-column-sort {
            background: ${theme.colors.transparent};
          }
        }
        &:last-child {
          & > td {
            border-bottom-color: ${theme.colors.transparent};
          }
        }
      }
    }
    .ant-table-thead {
      & > tr {
        & > th {
          background: ${theme.colors.light_grey};
          font-family: 'Inter';
          padding: 0 32px;
          font-weight: normal;
          line-height: 20px;
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    background-color: ${theme.colors.transparent};
                  }
                }
              }
            }
          }
        }
      }
    }
    &.ant-table.ant-table-middle {
      .ant-table-thead {
        .ant-table-column-sorters {
          padding: 0;
          .ant-table-column-sorter-inner .anticon {
            color: ${theme.colors.grey_stone};
          }
          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: ${theme.colors.black_sorted};
          }
        }
        .ant-table-column-sorter-inner {
          margin-top: 0;
        }
      }
    }
    .sort-space-none {
      .ant-table-column-sorters {
        justify-content: flex-start;
      }
      .ant-table-column-title {
        flex: 0 0 auto;
      }
      &.flex-end {
        .ant-table-column-sorters {
          justify-content: flex-end;
        }
      }
    }
    .ant-avatar {
      min-width: 40px;
    }
  }
  .ant-table-title {
    padding: 0 !important;
  }
`;
function modifyACHAccountsModal(achAccounts) {
  var resultantACHAccounts = [];
  achAccounts.map(function (item) {
    resultantACHAccounts.push({
      achInstructionID: item.ACHInstructionID,
      bankName: item.BankName,
      nickName: item.NickName,
      routing: item.BankRoutingNumber,
      accnumber: item.BankAccountNumber,
      nameOnAccount: item.NameOnAccount,
      acctype: item.BankAccountTypeName,
      addendumPurchaseDescription: item.AddendumPurchaseDescription,
      addendumRedemptionDescription: item.AddendumRedeptionDescription,
      permissions: item.Permissions.split(','),
    });
  });
  return resultantACHAccounts;
}

function modifyclientTeamInfoModal(clientTeamInfo) {
  var clientTeamDetails = [];
  clientTeamInfo.map(function (item) {
    clientTeamDetails.push({
      Name: item.Name,
      PhoneNumber: item.PhoneNumber,
      EmailAddress: item.EmailAddress
    });
  });
  return clientTeamDetails;
}

function modifyWireAccountsModal(wireAccounts) {
  var resultantWireAccounts = [];
  wireAccounts.map(function (item) {
    resultantWireAccounts.push({
      wireInstructionID: item.WireInstructionID,
      bankName: item.BankName,
      nickName: item.NickName,
      routing: item.ABANumber,
      accnumber: item.BankAccountNumber,
      beneficiaryName: item.BeneName,
      beneficiaryAccNumber: item.BeneAccountNumber,
      bankCity: item.BankCity.trim(),
      bankState: item.BankState.trim(),
      beneficiaryDetails: item.BeneDetails,
      details: item.BeneDetails,
      permissions: item.Permissions.split(','),
    });
  });
  return resultantWireAccounts;
}

function modifyPeerClientModal(peerAccounts) {
  var resultantPeeraccounts = [];
  peerAccounts.map(function (item) {
    resultantPeeraccounts.push({
      participantName: item.EntityName,
      accnumber: item.AccountNumber,
      productType: item.FundShortName.trim(),
      peerPermissions: item.Accounts.split(','),
    });
  });
  return resultantPeeraccounts;
}

function modifyPeerAccountModal(peerAccounts) {
  var resultantPeeraccounts = [];
  peerAccounts.map(function (item) {
    resultantPeeraccounts.push({
      participantName: item.EntityName,
      accnumber: item.AccountNumber,
      productType: item.FundShortName.trim(),
    });
  });
  return resultantPeeraccounts;
}



function modifyTeamMemberDetailtModalFromClient(teamMemberInfo) {
  var resultantTeamMemberInfo = [];
  teamMemberInfo.map(function (item) {
    resultantTeamMemberInfo.push({
      contactId: item.ContactID,
      firstName: item.FirstName,
      lastName: item.LastName,
      email: item.EmailAddress,
      lastLoginDate: item.LastLogin === "" ? null : item.LastLogin,
      permissions: removeDuplicateItemsFromString(item.AccessType),
      loginid: item.WebUserKey,
      phone: item.PhoneNumber,
      title: item.Title,
      mobile: item.MobileNumber,
      fax: item.FaxNumber,
    });
  });
  var filteredTeamMemeberInfo = resultantTeamMemberInfo.filter(function (el) {
    return  el.permissions != "";
  });
  return filteredTeamMemeberInfo;
}
function removeDuplicateItemsFromString(str) {
  var permissionItems = str.split(',');
  var uniquePermissions = [...new Set(permissionItems)];
  if (uniquePermissions.length > 0) {
    for (var i = 0; i < uniquePermissions.length; i++) {
      if (uniquePermissions[i] === "No Access") {
        uniquePermissions.splice(i, 1);
      }
    }
    return uniquePermissions.join(",")
  }
  return str;
}
function modifyTeamMemberDetailtModal(teamMemberInfo) {
  var resultantTeamMemberInfo = [];

  teamMemberInfo.map(function (item) {
    if (item.StatementRecipient == 'true') {
      resultantTeamMemberInfo.push({
        contactId: item.ContactID,
        firstName: item.FirstName,
        lastName: item.LastName,
        email: item.EmailAddress,
        lastLoginDate: item.LastLogin,
        permissions: item.AccessType + ', ' + 'Statement Recipient',
        loginid: item.WebUserKey,
        phone: item.PhoneNumber,
        title: item.Title,
        mobile: item.MobileNumber,
        fax: item.FaxNumber,
      });
    } else {
      resultantTeamMemberInfo.push({
        contactId: item.ContactID,
        firstName: item.FirstName,
        lastName: item.LastName,
        email: item.EmailAddress,
        lastLoginDate: item.LastLogin,
        permissions: item.AccessType,
        loginid: item.WebUserKey,
        phone: item.PhoneNumber,
        title: item.Title,
        mobile: item.MobileNumber,
        fax: item.FaxNumber,
      });
    }
  });
  return resultantTeamMemberInfo;
}

const OrganisationalSettingsContainer = (props) => {
  const showMoveFunds = useSelector(
    (state) => state.siteInfo.allowOnlineTransact
  );
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const rgbaPrimary = useSelector((state) => state.siteInfo.rgbaPrimary);
  const history = useHistory();
  const [accData, setAccData] = useState(null);
  const [accType, setAccType] = useState(null);
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const [isRemoveAccountSuccess, setIsRemoveAccountSuccess] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [viewMemberDetails, setViewMemberDetails] = useState(false);
  const [achAccounts, setAchAccounts] = useState([]);
  const [clientManagementInfo, setclientManagementInfo] = useState([]);
  const [wireAccounts, setWireAccounts] = useState([]);
  const [peerAccounts, setPeerAccounts] = useState([]);
  const [teamMemberDetails, setTeamMemberDetails] = useState([]);
  const [selectedAccountData, setSelectedAccountData] = useState({
    AccName: '',
  });
  const [showHideAchWirePayee, setShowHideAchWirePayee] = useState(false);
  const [showHideP2P, setShowHideP2P] = useState(false);
  const [orgSettings, setOrgSettings] = useState({
    entityName: '',
    fiscalYearEnd: '',
    legalName: '',
    county: '',
    taxId: '',
    mobile: '',
    addressLine1: '',
    addressLine2: '',
    fax: '',
    state: '',
    city: '',
    zipcode: '',
  });
  const clientDetails = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );

  const ProgramLevelTransPermission = useSelector(
    (state) => state.siteInfo.allowOnlineTransact
  );

  const selectedAccount = useSelector(
    (state) => state.accountProfileSlice.selectedAccount
  );
  const accountProfile = useSelector(
    (state) => state.accountProfileSlice.accountProfile
  );
  const [activeTab, setActiveTab] = useState('ACH');
  const defaultAddEditAccObj = {
    isAddEditAcc: false,
    selectedRecord: null,
    type: null,
  };
  const [addEditAccountObject, setAddEditAccountObject] = useState({
    ...defaultAddEditAccObj,
  });
  const ssnorTIN = clientDetails.payload[0].ssnortin;

  const p2pMenuPermissions = useSelector((state) =>
    state.menuPermissions.menuPermissionOptions.includes(25)
  );
  const webuserAccessType = useSelector((state) => state.userSlice.webUserType);

  useEffect(() => {
    const id = Number(props.match.params.id);
    if (accountDetails.length > 0) {
      const data = accountDetails[0].accountDetails.filter(
        (acc) => acc.key === id
      );
      if (data.length > 0) {
        setAccData(data[0]);
      }
    }
    props.location.accType && setActiveTab(props.location.accType);
  }, [props]);

  useEffect(() => {
    if (showMoveFunds === true) {
      setActiveTab('details');
    } else if (ProgramLevelTransPermission !== true) {
      setActiveTab('Team Members');
    } else if (showHideAchWirePayee === true && showHideP2P === true) {
      setActiveTab('ACH');
    } else if (showHideAchWirePayee === false && showHideP2P === false) {
      setActiveTab('Team Members');
    } else if (showHideAchWirePayee === true && showHideP2P === false) {
      setActiveTab('ACH');
    }
  }, [ProgramLevelTransPermission, showHideAchWirePayee, showHideP2P]);

  useEffect(() => {
    if (showMoveFunds === true) {
      GetClientDetails(ssnorTIN)
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            setOrgSettings(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
        GetClientTeamManagementInfo(ssnorTIN)
        .then((response) => {
          if (response.data.length > 0) {
            setclientManagementInfo(modifyclientTeamInfoModal(response.data));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [showMoveFunds]);

  useEffect(() => {
    if (ProgramLevelTransPermission === true) {
      if (props.isGlobalSettingsPage) {
        if (p2pMenuPermissions === true) {
          if (webuserAccessType === 0) {
            GetClientLevelTransBankingInstPermission(ssnorTIN, null)
              .then((response) => {
                if (response.data === true) {
                  setShowHideP2P(true);
                  //Get Client Level P2P instructions
                  GetP2PInstructionsClientLevel();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            setShowHideP2P(true);
            //Get Client Level P2P instructions
            GetP2PInstructionsClientLevel();
          }
        }

        if (webuserAccessType === 0) {
          GetClientLevelTransBankingInstPermission(ssnorTIN, null)
            .then((response) => {
              if (response.data === true) {
                setShowHideAchWirePayee(true);
                GetClientLevelWireInstructions();
                GetClientLevelAchInstructions();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setShowHideAchWirePayee(true);
          GetClientLevelWireInstructions();
          GetClientLevelAchInstructions();
        }
      } else {
        const data = accountProfile.AccountDetails.filter(
          (acc) =>
            acc.AccountUniqueID.trim() ===
            selectedAccount.AccountUniqueID.trim()
        );
        setSelectedAccountData(data[0]);

        if (p2pMenuPermissions === true) {
          if (webuserAccessType === 0) {
            GetClientLevelTransBankingInstPermission(
              ssnorTIN,
              selectedAccount.AccountUniqueID.trim()
            )
              .then((response) => {
                if (response.data === true) {
                  setShowHideP2P(true);
                  //Get Account Level P2P instructions
                  GetAccountLevelP2PInstructions();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            setShowHideP2P(true);
            //Get Account Level P2P instructions
            GetAccountLevelP2PInstructions();
          }
        }

        if (webuserAccessType === 0) {
          GetClientLevelTransBankingInstPermission(
            ssnorTIN,
            selectedAccount.AccountUniqueID.trim()
          )
            .then((response) => {
              if (response.data === true) {
                setShowHideAchWirePayee(true);
                GetAccountLevelWireInstructions();
                GetAccountLevelAchInstructions();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setShowHideAchWirePayee(true);
          GetAccountLevelWireInstructions();
          GetAccountLevelAchInstructions();
        }
      }
    } else {
      if (!props.isGlobalSettingsPage) {
        const data = accountProfile.AccountDetails.filter(
          (acc) =>
            acc.AccountUniqueID.trim() ===
            selectedAccount.AccountUniqueID.trim()
        );
        setSelectedAccountData(data[0]);
      }
    }
  }, [props.isGlobalSettingsPage]);

  useEffect(() => {
  if(props.isGlobalSettingsPage)
  {
  GetTeamMemberDetails(ssnorTIN)
  .then((response) => {
         if (response.data.length > 0) {
        setTeamMemberDetails(modifyTeamMemberDetailtModalFromClient(response.data));
      }
  })
  .catch((error) => {
    console.log(error);
  });
  }
  else{
    GetTeamMemberDetailsByAccount(selectedAccount.AccountUniqueID.trim())
    .then((response) => {
           if (response.data.length > 0) {
          setTeamMemberDetails(modifyTeamMemberDetailtModalFromClient(response.data));
        }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  }, [props.isGlobalSettingsPage]);

  function GetAccountLevelP2PInstructions() {
    GetP2PInstructions(selectedAccount.AccountUniqueID.trim())
      .then((response) => {
        if (response.data.length > 0) {
          setPeerAccounts(modifyPeerAccountModal(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetP2PInstructionsClientLevel() {
    GetP2PInstructionsBySSNORTIN(ssnorTIN)
      .then((response) => {
        if (response.data.length > 0) {
          setPeerAccounts(modifyPeerClientModal(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function GetClientLevelWireInstructions() {
    GetWirePayeeInstructions(ssnorTIN, null)
      .then((response) => {
        if (response.data.length > 0) {
          setWireAccounts(modifyWireAccountsModal(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetClientLevelAchInstructions() {
    GetACHPayeeInstructions(ssnorTIN, null)
      .then((response) => {
        if (response.data.length > 0) {
          setAchAccounts(modifyACHAccountsModal(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetAccountLevelWireInstructions() {
    GetWirePayeeInstructions(ssnorTIN, selectedAccount.AccountUniqueID)
      .then((response) => {
        if (response.data.length > 0) {
          setWireAccounts(modifyWireAccountsModal(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetAccountLevelAchInstructions() {
    GetACHPayeeInstructions(ssnorTIN, selectedAccount.AccountUniqueID)
      .then((response) => {
        if (response.data.length > 0) {
          setAchAccounts(modifyACHAccountsModal(response.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleAction = (type, action, data) => {
    //setAccType(type);
    //setSelectedRecord(data);
    if (action === 'details') {
      if (type === 'Team Member') {
        setViewMemberDetails(!viewMemberDetails);
      } else {
        setIsDetailsModalVisible(!isDetailsModalVisible);
      }
    }
    if (action === 'remove') {
      setIsRemoveModalVisible(!isRemoveModalVisible);
      setIsRemoveAccountSuccess(false);
    }
    setAccType(type);
    setSelectedRecord(data);
  };

  const handleModalCancel = (action) => {
    if (action === 'details') {
      setIsDetailsModalVisible(!isDetailsModalVisible);
    }
    if (action === 'remove') {
      setIsRemoveModalVisible(!isRemoveModalVisible);
      setIsRemoveAccountSuccess(false);
    }
    setAccType(null);
    setSelectedRecord(null);
  };

  const handleMenuClick = (e, record, type) => {
    e.domEvent && e.domEvent.stopPropagation();

    if (e.key === 'details') {
      handleAction(type, 'details', record);
    } else if (e.key === 'remove') {
      handleAction(type, 'remove', record);
    } else {
      setAddEditAccountObject({
        isAddEditAcc: !addEditAccountObject.isAddEditAcc,
        selectedRecord: record,
        type: type,
      });
    }
  };

  const getDetailsModalTitle = () => {
    if (accType === 'Team Member') {
      return 'Team Member';
    } else {
      return `${accType} Account`;
    }
  };

  const handleBackClick = () => {
    history.push('/dashboard/details/1');
  };

  return (
    <AppLayout>
      <OverrideStyles />
      {viewMemberDetails ? (
        <MemberDetails
          selectedRecord={selectedRecord}
          setViewMemberDetails={setViewMemberDetails}
          primaryColor={primary_color}
          rgbaHover={rgbaPrimary}
          handleMenuClick={handleMenuClick}
        />
      ) : (
        <>
          {addEditAccountObject.isAddEditAcc ? (
            <AddEditAccounts
              defaultAddEditAccObj={defaultAddEditAccObj}
              addEditAccountObject={addEditAccountObject}
              setAddEditAccountObject={setAddEditAccountObject}
            />
          ) : (
            <ContentWrapper rgbaHover={rgbaPrimary}>
              <Banner
                bgcolor={primary_color}
                height={!props.isGlobalSettingsPage ? '152px' : '124px'}
              >
                <Box
                  display="flex"
                  alignitems="center"
                  height="100%"
                  minwidth="100%"
                  width="100%"
                  zindex="3"
                  position="relative"
                  padding={
                    !props.isGlobalSettingsPage
                      ? '40px 0 36px 0'
                      : '44px 0 36px 0'
                  }
                >
                  <Container>
                    {!props.isGlobalSettingsPage && (
                      <Anchor
                        onClick={handleBackClick}
                        fontsize={`${theme.font_size.size_l}`}
                        lineheight="20px"
                        color={`${theme.colors.pearl_river}`}
                        className="opac-text"
                      >
                        <ArrowLeftOutlined style={{ marginRight: '6px' }} />
                        Back to {selectedAccountData.AccName}
                      </Anchor>
                    )}
                    <Box
                      display="flex"
                      justifycontent="space-between"
                      alignitems="flex-start"
                      margin={!props.isGlobalSettingsPage ? '12px 0 0' : '0'}
                    >
                      <Box flex="1">
                        <Text
                          color={`${theme.colors.white}`}
                          fontSize="36px"
                          lineheight="44px"
                          label={
                            props.isGlobalSettingsPage
                              ? 'Organizational Settings'
                              : `Settings-${selectedAccountData.AccName}`
                          }
                        />
                      </Box>
                    </Box>
                  </Container>
                </Box>
              </Banner>
              <>
                <Tabs
                  defaultActiveKey={activeTab}
                  activeTabColor={primary_color}
                  // linkTextColor={primary_color}
                  activeKey={activeTab}
                  onTabClick={(key) => setActiveTab(key)}
                  tabsOptions={[
                    ...(showMoveFunds
                      ? [
                          {
                            content: (
                              <OrganisationalDetails data={orgSettings} data2={clientManagementInfo}/>
                            ),
                            key: 'details',
                            label: 'Organization Details',
                          },
                        ]
                      : []),
                    ...(showHideAchWirePayee
                      ? [
                          {
                            content: (
                              <AchAccountsContainer
                                achAccounts={achAccounts}
                                handleAction={handleAction}
                                handleMenuClick={handleMenuClick}
                                isGlobalSettingsPage={
                                  props.isGlobalSettingsPage
                                }
                              />
                            ),
                            key: 'ACH',
                            label: 'ACH Accounts',
                          },
                          {
                            content: (
                              <WireAccountsContainer
                                wireAccounts={wireAccounts}
                                handleAction={handleAction}
                                handleMenuClick={handleMenuClick}
                                isGlobalSettingsPage={
                                  props.isGlobalSettingsPage
                                }
                              />
                            ),
                            key: 'Wire',
                            label: 'Wire Accounts',
                          },
                        ]
                      : []),
                    ...(showHideP2P
                      ? [
                          {
                            content: (
                              <PeerAccountsContainer
                                peerAccounts={peerAccounts}
                                handleAction={handleAction}
                                handleMenuClick={handleMenuClick}
                                isGlobalSettingsPage={
                                  props.isGlobalSettingsPage
                                }
                              />
                            ),
                            key: 'Participant',
                            label: 'Participant Accounts',
                          },
                        ]
                      : []),
                    {
                      content: (
                        <TeamMembersContainer
                          teamMembers={teamMemberDetails}
                          handleAction={handleAction}
                          handleMenuClick={handleMenuClick}
                          isGlobalSettingsPage={props.isGlobalSettingsPage}
                        />
                      ),
                      key: 'Team Members',
                      label: 'Team Members',
                    },
                  ]}
                  type="horizontal-tab"
                />
              </>
              {isDetailsModalVisible && (
                <Modal
                  title={getDetailsModalTitle()}
                  width={590}
                  bodyStyle={{
                    padding: '32px 32px 34px 32px',
                  }}
                  maskStyle={{
                    backgroundColor: `${theme.rgba.modal}`,
                    zIndex: '1051',
                  }}
                  content={
                    <DetailsModalContent
                      selectedRecord={selectedRecord}
                      handleModalCancel={handleModalCancel}
                      type={accType}
                      addEditAccountObject={addEditAccountObject}
                      setAddEditAccountObject={setAddEditAccountObject}
                      isGlobalSettingsPage={props.isGlobalSettingsPage}
                    />
                  }
                  visible={isDetailsModalVisible}
                  onCancel={() => handleModalCancel('details')}
                  footer={null}
                  centered={true}
                />
              )}

              {isRemoveModalVisible && (
                <Modal
                  title={
                    isRemoveAccountSuccess
                      ? `Account ${
                          props.isGlobalSettingsPage ? 'Deleted' : 'Removed'
                        }`
                      : accType === 'Team Member'
                      ? `${
                          props.isGlobalSettingsPage ? 'Delete' : 'Remove'
                        } Team Member?`
                      : `${
                          props.isGlobalSettingsPage ? 'Delete' : 'Remove'
                        } ${accType} Account?`
                  }
                  width={590}
                  bodyStyle={{
                    padding: '32px 32px 34px 32px',
                  }}
                  maskStyle={{
                    backgroundColor: `${theme.rgba.modal}`,
                    zIndex: '1051',
                  }}
                  footer={null}
                  visible={isRemoveModalVisible}
                  onCancel={() => handleModalCancel('remove')}
                  content={
                    <RemoveAccountModal
                      selectedRecord={selectedRecord}
                      accType={accType}
                      accData={accData}
                      isRemoveAccountSuccess={isRemoveAccountSuccess}
                      setIsRemoveAccountSuccess={setIsRemoveAccountSuccess}
                      handleModalCancel={handleModalCancel}
                      isGlobalSettingsPage={props.isGlobalSettingsPage}
                    />
                  }
                />
              )}
            </ContentWrapper>
          )}
        </>
      )}
    </AppLayout>
  );
};

export default OrganisationalSettingsContainer;
