import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  theme,
  ButtonAnt,
  Text,
  Modal,
  InputNumberComponent,
  Tabs
} from '@pfmam/pfmam';
import { Form, Space, Radio, DatePicker } from 'antd';
import { Box, Anchor } from '../../../styles/globalStyle';
import { InvestmentSetlectStyle } from '../../../activityHistory/components/stopPayments/initiateStopPayments';
import { OverrideStyle } from '../../../common/components/datePicker';
import MultiTableParticipant from './multiTableParticipant';
import SingleDate from '../../common/singleDate';
import MultiDate from '../../common/multiDate';
import MultiTransferAccount from '../../common/multiTransferAccount';
import _ from 'lodash';
import { IconBg } from '../../moveFunds';
import BetweenInvIcon from '../../../assets/images/Transaction Icons/between-investments.png';
import BetweenAccICon from '../../../assets/images/Transaction Icons/between-accounts.png';
import ProgramPartICon from '../../../assets/images/Transaction Icons/program- participant.png';
import moment from 'moment';
import AccInvestmentTypeInput from '../../common/accInvestmentTypeInput';
import AccInvestmentTransferInput from '../../common/accInvestmentTransferInput';
import { Prompt } from 'react-router';
import { amountFormat } from '../../../utils/numberFormat';
import {
  getParticipantTitle
} from '../../../transactionPages/common/commonFunctions';
import { validateP2PAmount } from '../../../utils/common';
import {
  numberFormat,
  validateCheckAmount
} from '../../common/commonFunctions';
import DuplicateTransactionModalContent from '../../common/duplicateTransactionModalContent';
import { ValidateTransaction, GetP2PTransferInstructions, GetTransactionDefaultDay, IsFundValidBusinessDay, IsDuplicateTransferTransactionSingleAccount } from "../../../actions/actions";
export const SuffixButton = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${theme.font_size.size_xl};
    color: ${(props) => props.color};
    line-height: 24px;
    padding: 0;
    border: 0;
    height: 100%;
  }
`;
const OverrideButtonAnt = styled(ButtonAnt)`
  &&& {
    background: ${(props) => props.linkTextColor};
    color: white;
  }
`;

const TabsWrapper = styled.div`
  margin: 0 -32px;
  .nav-pills {
    .ant-tabs-nav {
      margin-bottom: 32px;
    }
  }
  .ant-tabs-nav-list {
    padding-left: 32px;
  }
  .ant-input-number-input {
    padding: 0 20px;
  }
  .multiple-dates-tab-content {
    .ant-input-number-input {
      text-align: right;
      padding: 0 20px;
    }
  }
  .field-wrapper {
    align-items: flex-start;
  }
`;
const Wrapper = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;
const ButtonWrapper = styled(Space)`
  justify-content: flex-end;
  width: 100%;
  min-width: 100%;
  .primary {
    width: 160px;
  }
  ${(props) =>
    props.spacing &&
    css`
      margin-top: 48px;
    `}
`;
const RadioItemsWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 15px;
`;
const TextSemibold = styled(Text)`
  font-family: 'Inter Semibold';
`;
const RegularText = styled.span`
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 24px;  
`;
const InputElementWrapper = styled.div`
  .ant-input-number-input {
    text-align: right;
  }
`;


const Title = styled(Text)`
  font-weight: bold;
`;



const TransferForm = ({
  form,
  accSelected,
  setAccSelected,
  selected,
  setSelected,
  showInvestmentTypeModal,
  setShowInvestmentTypeModal,
  setFormType,
  setFormData,
  setAccName,
  accName,
  setAmount,
  setSelectedPage,
  selectedPage,
  transferData,
  setDefaultDate,
  defaultDate,
  loading,
  setLoading,
  participantData,
  setParticipantData
}) => {
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const [showAccountsModal, setShowAccountsModal] = useState(false);
  const [vals, setVals] = useState({});
  const [formTotal, setFormTotal] = useState();
  const [availableBal, setAvailableBal] = useState(0);
  const [isInvalidDateModalVisible, setIsInvalidDateModalVisible] = useState(false);
  const [enterAmountModalVisible, setEnterAmountModalVisible] = useState(false);
  const [enterAmountModalText, setenterAmountModalText] = useState("");
  const [emptyInstructionsModalVisible, setEmptyInstructionsModalVisible] = useState(false)
  const [transferToData, setTransferToData] = useState([]);
  const [validationMessage, setvalidationMessage] = useState("");
  const [isTransactionInvalidModalVisible, setIsTransactionInvalidModalVisible] = useState(false);
  const [isDuplicateTrans, setIsDuplicateTrans] = useState();
  const [isTransactionInValid, setIsTransactionInValid] = useState();
  // check duplicate transactions when hits review button on main form and set accordingly, keep false by default
  const [isDuplicateTransModalVisible, setIsDuplicateTransModalVisible] =
    useState(false);
  const [duplicateTransData, setDuplicateTransData] = useState([]);
  const duplicateTransColumns = [
    {
      title: 'Confirmation #',
      dataIndex: 'Confirm #',
      key: 'Confirm #',
      width: 190,
      align: 'left',
    },
    {
      title: 'Account Number',
      dataIndex: 'Account Number',
      key: 'Account Number',
      width: 210,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={text}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Posting Date',
      dataIndex: 'Posting Date',
      key: 'Posting Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
        </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'Created Date',
      key: 'Created Date',
      width: 212,
      align: 'left',
      render: (text, record) => (
        <>
          <Text
            label={moment(text).format('MMM DD, YYYY h:mm A')}
            color={`${theme.colors.black_secondary}`}
            fontSize={`${theme.font_size.size_xl}`}
            lineheight="24px"
          />
          <Text
            label={record['Created By']}
            color={`${theme.colors.grey_tertiary}`}
            fontSize={`${theme.font_size.size_xl}`}
            margin="4px 0 0"
            lineheight="20px"
          />
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'Amount',
      width: 148,
      align: 'right',
      render: (text) => <>{amountFormat(text)}</>,
    },
  ];

  if(selectedPage==='MultiAccountTransferForm'){
    setSelected('multiple');
  }

  function verifyTransactionDateIsValid(values) {
    if (values.date) {
      IsFundValidBusinessDay(values.date.format('YYYY-MM-DD'), values.accountinvestmentType.type.FundUniqueID)
        .then((response) => {
          if (!response.data) {
            setIsInvalidDateModalVisible(true);
          }
          else {
            validateDuplicateTransaction(values);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    else {
      let entryLength = values.multiDate.length;
      let resultantLength = 0;
      let isInvalidDate = [];
      values.multiDate.map((obj) => {
        IsFundValidBusinessDay(obj.date.format('YYYY-MM-DD'), values.accountinvestmentType.type.FundUniqueID)
          .then((response) => {
            resultantLength = resultantLength + 1;
            if (!response.data) {
              isInvalidDate.push(true);
            }

            if (entryLength === resultantLength && isInvalidDate.length > 0) {
              setIsInvalidDateModalVisible(true);
            } else if (entryLength === resultantLength) {
              validateDuplicateTransaction(values);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }

  }

  function validateDuplicateTransaction(values) {
    console.log(selectedPage);
    console.log(selected);
    //Checking for duplicate Transactions
    if (selectedPage === 'SingleAccountTransferForm' && selected === 'single') {
      ValidateTransaction(values.date.format('YYYY-MM-DD'), defaultDate.format('YYYY-MM-DD'), values.amount, values.accountinvestmentType.type.FundUniqueID, values.accountinvestmentType.AccountUniqueID, 5) // 5 mean Transfer between same Investment type to different account
        .then((response) => {
          if (!response.data.IsValid) {
            setIsTransactionInvalidModalVisible(true);
            setvalidationMessage(response.data.ErrorMessage);
            setIsTransactionInValid(true);
          }
          else {
            setIsTransactionInvalidModalVisible(false);
            setIsTransactionInValid(false);

          }
        })
        .catch((error) => {
          console.log(error);
        });


      IsDuplicateTransferTransactionSingleAccount(false, values.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, values.bankAccount.AccountUniqueID, values.amount, 6, 5)
        .then((response) => {
          if (response.data !== null && response.data.Table.length > 0) {
            setDuplicateTransData(response.data.Table);
            setIsDuplicateTransModalVisible(true);
            setIsDuplicateTrans(true);
          }
          else {
            setIsDuplicateTrans(false);
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }
    else if (selectedPage === 'SingleAccountTransferForm' && selected === 'multiple') {
      var dup = false;
      var invalidTrans = false;
      var dupList = [];
      var duplicateIncrement = 0;
      var invalidTransIncrement = 0;
      var AggrigatedMuiltiDate = [];
      var dupListFinalData = [];
      values.multiDate.reduce(function (res, value) {
        if (!res[value.date.format("YYYY-MM-DD")]) {
          res[value.date.format("YYYY-MM-DD")] = { date: value.date.format("YYYY-MM-DD"), amount: 0 };
          AggrigatedMuiltiDate.push(res[value.date.format("YYYY-MM-DD")])
        }
        res[value.date.format("YYYY-MM-DD")].amount = Math.round((res[value.date.format("YYYY-MM-DD")].amount + value.amount + Number.EPSILON) * 100) / 100;
        return res;
      }, {});

      AggrigatedMuiltiDate.map((obj, index) => {
        ValidateTransaction(obj.date, defaultDate.format("YYYY-MM-DD"), obj.amount, values.accountinvestmentType.type.FundUniqueID, values.accountinvestmentType.AccountUniqueID, 5) // 5 mean transfer between accounts
          .then((response) => {
            invalidTransIncrement++;
            if (!response.data.IsValid) {
              invalidTrans = true;
              setvalidationMessage(response.data.ErrorMessage);
            }
            if (invalidTransIncrement === AggrigatedMuiltiDate.length) {
              setIsTransactionInvalidModalVisible(invalidTrans);
              setIsTransactionInValid(invalidTrans);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });

      values.multiDate.map((obj, index) => {
        IsDuplicateTransferTransactionSingleAccount(false, obj.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, values.bankAccount.AccountUniqueID, obj.amount, 6, 5)
          .then((response) => {
            duplicateIncrement++;
            if (response.data !== null && response.data.Table.length > 0) {
              dupList = dupList.concat(response.data.Table);
              //setDuplicateTransData(dupList);
              dup = true;
            }
            if (duplicateIncrement === values.multiDate.length) {
              //remove the repeated duplicate data row
              buildDuplicateModalData(dupList,dupListFinalData);
              setDuplicateTransData(dupListFinalData);
              setIsDuplicateTransModalVisible(dup);
              setIsDuplicateTrans(dup);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
    }
  
    else if (selectedPage === 'MultiAccountTransferForm') {
      var dup = false;
      var dupList = [];
      var duplicateIncrement = 0;
      var dupListFinalData = [];
      function amount(item) {
        return item.amount;
      }
  
      function sum(prev, next) {
        return Math.round((prev + next + Number.EPSILON) * 100) / 100;
      }
      var totalAmount = values.multiTransferAccount.map(amount).reduce(sum);
      ValidateTransaction(values.date.format('YYYY-MM-DD'), defaultDate.format('YYYY-MM-DD'), totalAmount, values.accountinvestmentType.type.FundUniqueID, values.accountinvestmentType.AccountUniqueID, 5) // 5 mean Transfer between same Investment type to different account
        .then((response) => {
          if (!response.data.IsValid) {
            setIsTransactionInvalidModalVisible(true);
            setvalidationMessage(response.data.ErrorMessage);
            setIsTransactionInValid(true);
          }
          else {
            setIsTransactionInvalidModalVisible(false);
            setIsTransactionInValid(false);

          }
        })
        .catch((error) => {
          console.log(error);
        });

      values.multiTransferAccount.map((obj, index) => {
        IsDuplicateTransferTransactionSingleAccount(false, values.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, obj.multiAccount.AccountUniqueID, obj.amount, 6, 5)
          .then((response) => {
            duplicateIncrement++;
            if (response.data !== null && response.data.Table.length > 0) {
              dupList = dupList.concat(response.data.Table);
              //setDuplicateTransData(dupList);
              dup = true;
            }
            if (duplicateIncrement === values.multiTransferAccount.length) {
              //remove the repeated duplicate data row
              buildDuplicateModalData(dupList,dupListFinalData);
              setDuplicateTransData(dupListFinalData);
              setIsDuplicateTransModalVisible(dup);
              setIsDuplicateTrans(dup);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
    }

    else if (selectedPage === 'InvestmentTypeTransfer') {
      ValidateTransaction(values.date.format('YYYY-MM-DD'), defaultDate.format('YYYY-MM-DD'), values.amount, values.accountinvestmentType.type.FundUniqueID, values.accountinvestmentType.AccountUniqueID, 7) // Exchange Shares
        .then((response) => {
          if (!response.data.IsValid) {
            setIsTransactionInvalidModalVisible(true);
            setvalidationMessage(response.data.ErrorMessage);
            setIsTransactionInValid(true);
          }
          else {
            setIsTransactionInvalidModalVisible(false);
            setIsTransactionInValid(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      IsDuplicateTransferTransactionSingleAccount(true, values.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, values.accountinvestmentType.AccountUniqueID, values.amount, 8, 0)
        .then((response) => {
          if (response.data !== null && response.data.Table.length > 0) {
            setDuplicateTransData(response.data.Table);
            setIsDuplicateTransModalVisible(true);
            setIsDuplicateTrans(true);
          }
          else {
            setIsDuplicateTrans(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });

    }

    else if (selectedPage === 'ProgramTransfer') {
      let entryLength = values.Participant.length;
      var dup = false;
      let resultantLength = 0;
      let resultantData = [];
      function amount(item) {
        return Number(item.amount);
      }

      function sum(prev, next) {
        return Math.round((prev + next + Number.EPSILON) * 100) / 100;
      }
      var totalAmount = values.Participant.map(amount).reduce(sum);
      ValidateTransaction(values.date.format('YYYY-MM-DD'), defaultDate.format('YYYY-MM-DD'), totalAmount, values.accountinvestmentType.type.FundUniqueID, values.accountinvestmentType.AccountUniqueID, 25) // 25 mean P2P Transfer
        .then((response) => {
          if (!response.data.IsValid) {
            setIsTransactionInvalidModalVisible(true);
            setvalidationMessage(response.data.ErrorMessage);
            setIsTransactionInValid(true);
          }
          else {
            setIsTransactionInvalidModalVisible(false);
            setIsTransactionInValid(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      values.Participant.map((obj, index) => {
        IsDuplicateTransferTransactionSingleAccount(false, values.date.format('YYYY-MM-DD'), values.accountinvestmentType.AccountUniqueID, obj.participant.RecipientAccountUniqueID, obj.amount, 26, 25)
          .then((response) => {
            resultantLength = resultantLength + 1;
            if (response.data !== null && response.data.Table.length > 0) {
              resultantData = resultantData.concat(response.data.Table);
              setDuplicateTransData(resultantData);
              dup = true;
            }
            if (entryLength === resultantLength && resultantData.length > 0) {
              setIsDuplicateTransModalVisible(dup);
              setIsDuplicateTrans(true);
            }
            else if (entryLength === resultantLength) {
              setIsDuplicateTrans(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
    }
  }

  function buildDuplicateModalData(duplicateData, resultantData) {
    duplicateData.map(function (item) {
      if (!(resultantData.filter(e => e["Confirm #"] === item["Confirm #"]).length > 0)) {
        resultantData.push(item);
      }
    });
    return resultantData;
  }

  const onFinish = (values) => {
    if (selectedPage === "ProgramTransfer") {
      const filteredObjects = ['accountinvestmentType', 'date'];
      let filtered = Object.keys(values)
        .filter(key => !filteredObjects.includes(key))
        .reduce((obj, key) => {
          obj[key] = values[key];
          return obj;
        }, {});
      var checkEmptyInstructions = Object.keys(filtered)
        .map(function (key) {
          return filtered[key];
        });
      if (checkEmptyInstructions.length === 0) {
        setEmptyInstructionsModalVisible(true);
      }
      else {
        let checkAmountField = checkEmptyInstructions.filter(obj => {
          return obj.amount != '' && obj.amount != null && obj.amount != "null";
        });
        // let checkAmountFieldZero = checkEmptyInstructions.filter(obj => {
        //   return  obj.amount === "null";
        // });
        // if (checkAmountFieldZero.length > 0) {
        //   setenterAmountModalText("One or more of your entered amounts is Zero");
        //   setEnterAmountModalVisible(true);
        // }

        if (checkAmountField.length === 0) {
          setenterAmountModalText("One or more of your entered amounts are invalid");
          setEnterAmountModalVisible(true);
        }


        else {
          var Participant = [];
          var finalResult = { Participant: [], accountinvestmentType: {}, date: moment() };
          checkAmountField.map(function (item) {
            Participant.push({
              participant: item.participant,
              amount: item.amount
            })
          })
          finalResult.Participant = Participant;
          Object.assign(finalResult.accountinvestmentType, values.accountinvestmentType);
          Object.assign(finalResult.date, values.date);
          if (values.hasOwnProperty('amount')) {
            setAmount(values['amount']);
          }
          else {
            setAmount(formTotal);
          }
          setSelected('multiple');
          setFormData(finalResult);
          verifyTransactionDateIsValid(finalResult);
        }
      }
    }
    else {
      if (values.hasOwnProperty('amount')) {
        setAmount(values['amount']);
      }
      else {
        setAmount(formTotal);
      }
      setFormData(values);
      verifyTransactionDateIsValid(values);
    }
  };


  useEffect(() => {
    if (isDuplicateTrans === false && isTransactionInValid === false) {
      setFormType('review');
    }
  }, [isDuplicateTrans, isTransactionInValid]);

  useEffect(() => {
    if (form.getFieldValue('accountinvestmentType')) {
      if(selectedPage==="ProgramTransfer")
      {
       getInstructions();
      }
      selectTransferFrom(form.getFieldValue('accountinvestmentType').obj, form.getFieldValue('accountinvestmentType').type);
    }
  }, [])
  const handleDuplicateModalSubmit = () => {
    setIsDuplicateTransModalVisible(false);
    setFormType('review');
  };

  const handleDuplicateModalCancel = () => {
    setIsDuplicateTransModalVisible(false);
  };
  const buttonHandler = (type) => {
    return setSelected(type);
  };

  const AccountsModal = () => {
    const onAccountsChange = (e) => {
      form.setFieldsValue({
        bankAccount: e.target.value,
      });
      setShowAccountsModal(!showAccountsModal);
    };

    return (
      <>
        {selectedPage === 'InvestmentTypeTransfer' ? (
          <>
            {transferToData.map((obj) => {
              return (
                <Radio.Group size="large" onChange={onAccountsChange} key={obj.AccountUniqueID}>
                  <Box boxcolor={`${theme.colors.light_grey}`} padding="8px 32px"
                    display="flex"
                    justifycontent="space-between">
                    <Title
                      label={obj.DisplayName}
                      fontSize={`${theme.font_size.size_xl}`}
                      lineheight="20px"
                      color={`${theme.colors.clean_black}`}
                    />
                    <Text
                      label="Available Balance"
                      fontSize={`${theme.font_size.size_l}`}
                      lineheight="20px"
                      color={`${theme.colors.clean_black}`}
                    />
                  </Box>
                  {obj.FundDetails.length >= 1 ?
                    null : (
                      <Box boxcolor={`${theme.colors.light_grey}`} padding="8px 32px">
                        <Text
                          label={'No other Investment Types Found to Transfer'}
                          fontSize={`${theme.font_size.size_l}`}
                          lineheight="20px"
                          color={`${theme.colors.clean_black}`}
                        />
                      </Box>
                    )}
                  {obj.FundDetails.map((fundetailsObj) => {
                    return (
                      <Radio.Button key={fundetailsObj.FundUniqueID}
                        value={{ AccName: fundetailsObj.DisplayName, AccountUniqueID: obj.AccountUniqueID, type: fundetailsObj }}
                      >
                        <RadioItemsWrapper>
                          <span> {fundetailsObj.FundShortName}</span>

                          <span>{amountFormat(fundetailsObj.AvailBalance)}</span>
                        </RadioItemsWrapper>

                      </Radio.Button>
                    )
                  })}
                </Radio.Group>
              );
            })}
          </>
        ) : (
          <>
            {selectedPage === 'SingleAccountTransferForm' && transferToData.length >= 1 ?
              null : (
                <Box boxcolor={`${theme.colors.light_grey}`} padding="8px 32px">
                  <Text
                    label={'No other Accounts Found to Transfer'}
                    fontSize={`${theme.font_size.size_l}`}
                    lineheight="20px"
                    color={`${theme.colors.clean_black}`}
                  />
                </Box>
              )}
            {transferToData.map((obj) => {
              return (
                <Radio.Group size="large" onChange={onAccountsChange} key={obj.AccountUniqueID}>
                  <Box boxcolor={`${theme.colors.light_grey}`} padding="8px 32px" display="flex"
                    justifycontent="space-between">
                    <Title
                      label={obj.DisplayName}
                      fontSize={`${theme.font_size.size_xl}`}
                      lineheight="20px"
                      color={`${theme.colors.clean_black}`}
                    />
                    <Text
                      label="Available Balance"
                      fontSize={`${theme.font_size.size_l}`}
                      lineheight="20px"
                      color={`${theme.colors.clean_black}`}
                    />
                  </Box>

                  <Radio.Button key={obj.FundDetails.FundUniqueID}
                    value={{ AccName: obj.DisplayName, AccountUniqueID: obj.AccountUniqueID, type: obj.FundDetails }}
                  >
                    <RadioItemsWrapper>
                      <span> {obj.FundDetails.FundShortName.trim()}</span>

                      <span>{amountFormat(obj.FundDetails.AvailBalance)}</span>
                    </RadioItemsWrapper>

                  </Radio.Button>

                </Radio.Group>
              );
            })}
          </>
        )}
      </>
    );
  };

  const InvestmentModalContent = () => {
    const onInvestmentTypeChange = (e) => {
      console.log('page', selectedPage);
      console.log('transferTo', transferToData);
      console.log('tot', e.target.value.balance);
      setAvailableBal(e.target.value.type.AvailBalance);
      form.resetFields();
      form.setFieldsValue({
        accountinvestmentType: e.target.value,
      });
      console.log(form.getFieldValue('accountinvestmentType'));
      if (selectedPage === "ProgramTransfer" && e.target.value.type.FundUniqueID !== 0) {
        setFormTotal(null);
        setLoading(true);
        getInstructions();
      }

      if (e.target.value.type.FundUniqueID !== 0) {
        let pendingTransactiontype = getTransactionPendingType()
        GetTransactionDefaultDay(e.target.value.type.FundUniqueID, pendingTransactiontype).then((response) => {
          if (response.data !== null) {
            //var date1 = new Date(response.data);
            var defaultMoment = moment(response.data.toString());//.add(1,'days');//moment(year+'-'+month+'-'+day,'YYYY-MM-DD')
            
            if((selectedPage === 'SingleAccountTransferForm' && selected === 'single') || (selectedPage === 'SingleAccountTransferForm' && selected === 'multiple')){
              form.setFieldsValue({
                multiDate: [
                  { date: defaultMoment, amount: '' },
                  { date: defaultMoment, amount: '' },
                ]
              });
              form.setFieldsValue({
                date: defaultMoment,
              });
            } else {
              form.setFieldsValue({
                date: defaultMoment,
              });
            }
            setDefaultDate(defaultMoment);
          }
        })
          .catch((error) => {
            console.log(error);
          });
      }

      setShowInvestmentTypeModal(!showInvestmentTypeModal);
    };

    return (
      <>
        {transferData.map((obj) => {
          return (
            <Radio.Group
              size="large"
              onChange={onInvestmentTypeChange}
              key={obj.AccountUniqueID}
            // disabled={selectedPage === 'AccountsTransfer' && transferData.length > 1 ? false : true}
            >
              <Box boxcolor={`${theme.colors.light_grey}`} padding="8px 32px" display="flex"
                justifycontent="space-between">
                <Title
                  label={obj.DisplayName}
                  fontSize={`${theme.font_size.size_xl}`}
                  lineheight="20px"
                  color={`${theme.colors.clean_black}`}
                />
                <Text
                  label="Available Balance"
                  fontSize={`${theme.font_size.size_l}`}
                  lineheight="20px"
                  color={`${theme.colors.clean_black}`}
                />
              </Box>

              {obj.FundDetails.map((prog) => {
                return (
                  <Radio.Button
                    key={prog.FundUniqueID}
                    // disabled={prog.AvailBalance === 0 ? true : false}
                    value={{ AccName: obj.DisplayName, AccountUniqueID: obj.AccountUniqueID, type: prog, obj: obj }}
                    onClick={() => {
                      selectTransferFrom(obj, prog);
                    }
                    }
                  >
                    <RadioItemsWrapper>
                      <span>{prog.FundShortName}</span>
                      <span>{amountFormat(prog.AvailBalance)}</span>
                    </RadioItemsWrapper>

                  </Radio.Button>
                );
              })}
            </Radio.Group>
          );
        })}
      </>
    );
  };
  const selectTransferFrom = (obj, prog) => {
    setAccSelected(false);
    setAccName(obj.DisplayName);
    if (selectedPage === 'InvestmentTypeTransfer') {
      let data = _.cloneDeep(obj);
      let index = data.FundDetails.findIndex((obj) => {
        return obj.FundShortName === prog.FundShortName;
      });
      if (index > -1) {
        data.FundDetails.splice(index, 1);
      }
      const arr = [];
      arr.push(data);
      setTransferToData(arr);
    } else if (selectedPage === 'SingleAccountTransferForm' || selectedPage === 'MultiAccountTransferForm') {
      const filteredArray = [];
      const arr1 = _.cloneDeep(transferData);
      let accName1 = obj.DisplayName;
      let index = arr1.findIndex((object) => {
        return object.DisplayName === accName1;
      });
      if (index > -1) {
        arr1.splice(index, 1);
      }
      arr1.findIndex((object) => {
        let index12 = object.FundDetails.findIndex((object1) => {
          return object1.FundUniqueID === prog.FundUniqueID;
        });
        object.FundDetails = object.FundDetails[index12];
        if (index12 != -1) {
          filteredArray.push(object);
        }

      });
      setTransferToData(filteredArray);
    }
  }


  function getFormData(formElements) {
    return formElements.map((object, index) => {
      return {
        id: object.RecipientAccountUniqueID,
        entityName: object.EntityName + "*" + object.AccountNumber,
        participant: <Form.Item
          name={[index, 'participant']}
          fieldKey={[index, 'participant']}

        >
          {getParticipantTitle(
            object.EntityName,
            object.AccountNumber,
          )}
        </Form.Item>,
        amount:
          <InputElementWrapper>
            <Form.Item
              name={[index, "amount"]}
              fieldKey={[index, "amount"]}
              rules={[
                {
                  validator: (_, value) =>
                    value && validateP2PAmount(value)
                      ? validateCheckAmount(
                        value,
                        formTotal,
                        availableBal
                      )
                      : value
                        ? Promise.reject('Please enter a valid amount')
                        : Promise.resolve(),
                },
              ]}
            >
              <InputNumberComponent
                placeholder="$ Enter Amount"
                showHandleButton={false}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    let val = value !== '' ? numberFormat(value) : null;
                    if (val != null) {
                      if (String(value).includes('.')) {
                        let length = String(value).split('.')[1].length;
                        if (length > 2) {
                          return "$" + val;
                        }
                      }
                      val = amountFormat(val.toString().replace(/,/g, ''));
                    }
                    return val;
                  } else {
                    return value !== '' ? '$' + numberFormat(value) : null;
                  }
                }}
                parser={(value) => {
                  let val = value.replace(/\$\s?|(,*)/g, '');
                  if (value != "") {
                    const newData = form.getFieldValue();
                    newData[index]["amount"] = val;
                    return val;
                  }
                  return val;
                }
                }
              />
            </Form.Item>
          </InputElementWrapper>
      };
    })
  }

  const getInstructions = () => {   
    GetP2PTransferInstructions(form.getFieldValue('accountinvestmentType').type.FundUniqueID, form.getFieldValue('accountinvestmentType').AccountUniqueID).then((response) => {
      let prevData = form.getFieldValue();
      const filteredObjects = ['accountinvestmentType', 'date'];
      let filtered = Object.keys(prevData)
        .filter(key => !filteredObjects.includes(key))
        .reduce((obj, key) => {
          obj[key] = prevData[key];
          return obj;
        }, {});
      var checkAmountFields = Object.keys(filtered)
        .map(function (key) {
          return filtered[key];
        });

      if (checkAmountFields.length === 0) {
        if (response.data !== null && response.data.length > 0) {
          setParticipantData(getFormData(response.data));
          form.setFieldsValue(
            response.data.map((object) => {
              return {
                participant: object,
                amount: ""
              };
            })
          );
          setLoading(false);
        }
        else {
          setParticipantData([]);
          setLoading(false);
        }
      }
      else {
        form.setFieldsValue(
          participantData.map((object) => {
            var prevAmt = checkAmountFields.filter(function (el) {
              return el.participant.RecipientAccountUniqueID === object.id;
            });
            return {
              participant: object.participant,
              amount: prevAmt[0].amount === 'null' ? '' : prevAmt[0].amount
            };
          })
        );
        let sum = 0;
        const result = checkAmountFields.filter(obj => {
          return obj.amount != '' && obj.amount != 'null';
        });
        result.forEach((obj) => {
          let val = 0;
          if (obj.amount) {
            val = parseFloat(obj.amount);
          }
          sum = sum + val;
        });
        setFormTotal(sum);
        setLoading(false);

      }
    })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleTotal = (_, values) => {
    setVals(values);
  };

  const getTransactionPendingType = () => {
    //5-transfer
    //7-exchnage
    //25-program to program
    if (selectedPage === 'InvestmentTypeTransfer') {
      return 7;
    } else if (selectedPage === 'SingleAccountTransferForm' || selectedPage === 'MultiAccountTransferForm') {
      return 5;
    } else if (selectedPage === 'ProgramTransfer') {
      return 25;
    }
  };

  const getTitle = () => {
    if (selectedPage === 'InvestmentTypeTransfer') {
      return 'Transfer Between Investment Types';
    } else if (selectedPage === 'SingleAccountTransferForm') {
      return 'Transfer Between Single Account';
    } else if (selectedPage === 'MultiAccountTransferForm') {
      return 'Transfer Between Multiple Accounts';
    } else if (selectedPage === 'ProgramTransfer') {
      return 'Transfer to Program Participant';
    }
  };
  const disabledDate = (current) => {
    var x = moment().endOf("day");
    // Can not select days before default date and weekends
    return (current.isoWeekday() === 7 || current.isoWeekday() === 6 || current < defaultDate.startOf('day')); //&& current.isoWeekday() ===6;
  }
  const getIcon = () => {
    if (selectedPage === 'InvestmentTypeTransfer') {
      return BetweenInvIcon;
    } else if (selectedPage === 'SingleAccountTransferForm' || selectedPage === 'MultiAccountTransferForm') {
      return BetweenAccICon;
    } else if (selectedPage === 'ProgramTransfer') {
      return ProgramPartICon;
    }
  };
  return (
    <>
      <Prompt
        when={form.isFieldsTouched()}
        message={JSON.stringify({
          header: 'Discard Transaction?',
          content:
            'Leaving this page will discard this transaction. Are you sure you want to discard this transaction?',
        })}
      />
      <OverrideStyle />
      <InvestmentSetlectStyle />
      <Text
        label={'Transfer Funds'}
        fontSize="36px"
        color={`${theme.colors.black_secondary}`}
        lineheight="44px"
        margin="0 0 32px"
      />
      <Box
        border={`1px solid ${theme.colors.grey}`}
        borderwidth="0 0 1px 0"
        display="flex"
        padding="20px 32px 24px"
        boxcolor={theme.colors.white}
        radius="4px 4px 0 0"
      >
        <IconBg bgColor={`${primary_color}`}>
          <img src={getIcon()} alt="Single Bank Account" />
        </IconBg>
        <Box flex="1">
          <TextSemibold
            fontSize={`${theme.font_size.size_xl}`}
            color={`${theme.colors.black_secondary}`}
            label={getTitle()}
            lineheight="24px"
            margin="0 0 4px"
          />
          <Anchor
            fontsize={`${theme.font_size.size_l}`}
            lineheight="20px"
            color={`${theme.colors.blue_primay}`}
            display="inline-block"
            onClick={() => selectedPage == 'SingleAccountTransferForm' || selectedPage == 'MultiAccountTransferForm' ? setSelectedPage('AccountsTransfer') : setSelectedPage('TransferTypeCard')}
          >
            Change
          </Anchor>
        </Box>
      </Box>
      <Box
        boxcolor={`${theme.colors.white}`}
        padding="32px 32px 48px"
        radius="0 0 4px 4px"
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          requiredMark={false}
          className="forms"
          initialValues={selectedPage === "ProgramTransfer" ? {
            date: moment(),
          } : selectedPage === "MultiAccountTransferForm"?{
            multiTransferAccount: [
              { multiAccount: '', amount: '' },
              { multiAccount: '', amount: '' },
            ],
            date: moment()
          }: {
            multiDate: [
              { date: moment(), amount: '' },
              { date: moment(), amount: '' },
            ]}}
          onFinish={onFinish}
          onValuesChange={handleTotal}
        >
          <>
            <AccInvestmentTypeInput
              form={form}
              showInvestmentTypeModal={showInvestmentTypeModal}
              setShowInvestmentTypeModal={setShowInvestmentTypeModal}
              accName={accName}
              label="Transfer From"
              amount={
                form.getFieldValue('accountinvestmentType')
                  ? form.getFieldValue('accountinvestmentType').type.AvailBalance
                  : null
              }
            />
             
              {selectedPage === 'InvestmentTypeTransfer'
                ? (
                  <>
                   <AccInvestmentTransferInput
                form={form}
                showInvestmentTypeModal={showAccountsModal}
                setShowInvestmentTypeModal={setShowAccountsModal}
                accName={accName}
                label="Transfer To"
                amount={
                  form.getFieldValue('bankAccount')
                    ? form.getFieldValue('bankAccount').type.AvailBalance
                    : null
                }
              />
                  <SingleDate
                    vals={vals}
                    setFormTotal={setFormTotal}
                    dateLabel="Transaction Date"
                    formTotal={formTotal}
                    availableBal={form.getFieldValue('accountinvestmentType') !== undefined ? form.getFieldValue('accountinvestmentType').type.AvailBalance : availableBal}
                    disabledDate={disabledDate}
                  />
                  </>
              
            ):selectedPage === 'SingleAccountTransferForm'?  (
            <>  
             <AccInvestmentTransferInput
                form={form}
                showInvestmentTypeModal={showAccountsModal}
                setShowInvestmentTypeModal={setShowAccountsModal}
                accName={accName}
                label="Transfer To"
                amount={
                  form.getFieldValue('bankAccount')
                    ? form.getFieldValue('bankAccount').type.AvailBalance
                    : null
                }
              />       
              <TabsWrapper>
                <Tabs
                  defaultActiveKey={selected}
                  activeKey={selected}
                  activeTabColor={primary_color}
                  onTabClick={(key) => buttonHandler(key)}
                  tabsOptions={[
                    {
                      content: (
                        <Box padding="0 32px" margin="0 0 32px">
                          {selected === 'multiple' ? null : (
                            <SingleDate
                              vals={vals}
                              setFormTotal={setFormTotal}
                              dateLabel="Transaction Date"
                              formTotal={formTotal}
                              availableBal={form.getFieldValue('accountinvestmentType') !== undefined ? form.getFieldValue('accountinvestmentType').type.AvailBalance : availableBal}
                              disabledDate={disabledDate}
                            />
                          )}
                        </Box>
                      ),
                      key: 'single',
                      label: 'Single Date',
                    },
                    {
                      content: (
                        <Box
                          className="multiple-dates-tab-content"
                          padding="0 0 16px"
                        >
                          {selected === 'single' ? null : (
                            <MultiDate
                              vals={vals}
                              formTotal={formTotal}
                              setFormTotal={setFormTotal}
                              selectedPage={selectedPage}
                              availableBal={form.getFieldValue('accountinvestmentType') !== undefined ? form.getFieldValue('accountinvestmentType').type.AvailBalance : availableBal}
                              isRedemption={false}
                              disabledDate={disabledDate}
                              form={form}
                            />
                          )}
                        </Box>
                      ),
                      key: 'multiple',
                      label: 'Multiple Date',
                    },
                  ]}
                  type="nav-pills"
                />
              </TabsWrapper>
            </>
            ) :selectedPage === 'ProgramTransfer'?(<MultiTableParticipant
              vals={vals}
              formTotal={formTotal}
              setFormTotal={setFormTotal}
              selectedPage={selectedPage}
              form={form}
              availableBal={availableBal}
              participantData={participantData}
              defaultDate={defaultDate}
              loading={loading}
            />
            ):(
            <Box margin="0 0 48px">
              <MultiTransferAccount
                vals={vals}
                formTotal={formTotal}
                setFormTotal={setFormTotal}
                form={form}
                transferToData={transferToData}
                availableBal={form.getFieldValue('accountinvestmentType') !== undefined ? form.getFieldValue('accountinvestmentType').type.AvailBalance : availableBal}
                type={'MultiAccountTransferForm'}
              />

              <Form.Item
                label="Transaction Date"
                name="date"
                rules={[
                  {
                    type: 'object',
                    required: true,
                    message: 'Transaction Date Required',
                  },
                ]}
              >
                <DatePicker
                  format="MMM DD, YYYY"
                  allowClear={false}
                  className="suffix-left"
                  dropdownClassName="single-date-picker"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Box>
              )}
          </>
          <ButtonWrapper size={12}>
            <ButtonAnt
              label="Cancel"
              type="secondary"
              htmlType="button"
              onClick={() => setSelectedPage('TransferTypeCard')}
            />
            <OverrideButtonAnt linkTextColor={primary_color} label="Review" type="primary" htmlType="submit" />
          </ButtonWrapper>
        </Form>
      </Box>
      {isInvalidDateModalVisible && (
        <Modal
          title="Invalid Date"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isInvalidDateModalVisible}
          onCancel={() => setIsInvalidDateModalVisible(false)}
          content={
            <Wrapper>
              <RegularText>
                {"The Date you selected is Invalid or a Holiday for the Investment Type you selected. Please select other Date"}
              </RegularText>
            </Wrapper>}
          centered={true}
        />
      )}
      {emptyInstructionsModalVisible && (
        <Modal
          title=""
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={emptyInstructionsModalVisible}
          onCancel={() => setEmptyInstructionsModalVisible(false)}
          content={
            <Wrapper>
              <RegularText>
                {"No Participant instructions found"}
              </RegularText>
            </Wrapper>}
          centered={true}
        />
      )}

      {enterAmountModalVisible && (
        <Modal
          title="Error Message"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={enterAmountModalVisible}
          onCancel={() => setEnterAmountModalVisible(false)}
          content={
            <Wrapper>
              <RegularText>
                {enterAmountModalText}
                {/* {"One or more of your entered amounts are invalid"} */}
              </RegularText>
            </Wrapper>}
          centered={true}
        />
      )}

      {showInvestmentTypeModal && (
        <Modal
          title="Transfer From"
          width={660}
          bodyStyle={{
            padding: '0 0 64px 0',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={<InvestmentModalContent />}
          visible={showInvestmentTypeModal}
          onCancel={() => {
            setShowInvestmentTypeModal(!showInvestmentTypeModal);
            setAccSelected(true);
          }}
          footer={null}
          centered={true}
        />
      )}

      {showAccountsModal && (
        <Modal
          title={
            'Transfer To'
          }
          width={590}
          bodyStyle={{
            padding: '0 0 64px 0',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          content={<AccountsModal />}
          visible={showAccountsModal}
          onCancel={() => setShowAccountsModal(!showAccountsModal)}
          footer={null}
          centered={true}
        />
      )}
      {isDuplicateTransModalVisible && (
        <Modal
          title="Possible Duplicate Transaction"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isDuplicateTransModalVisible}
          onCancel={() => setIsDuplicateTransModalVisible(false)}
          content={
            <DuplicateTransactionModalContent
              columns={duplicateTransColumns}
              data={duplicateTransData}
              handleDuplicateModalCancel={handleDuplicateModalCancel}
              handleDuplicateModalSubmit={handleDuplicateModalSubmit}
            />
          }
          centered={true}
        />
      )}


      {isTransactionInvalidModalVisible && (
        <Modal
          title="Invalid Transaction"
          width={750}
          bodyStyle={{
            padding: '32px 0 40px',
          }}
          maskStyle={{
            backgroundColor: `${theme.rgba.modal}`,
            zIndex: '1051',
          }}
          footer={null}
          visible={isTransactionInvalidModalVisible}
          onCancel={() => setIsTransactionInvalidModalVisible(false)}
          content={
            <Wrapper>
              <RegularText>{validationMessage}</RegularText>
            </Wrapper>

          }
          centered={true}
        />
      )}
    </>
  );
};

export default TransferForm;