import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Layout, Space } from 'antd';
import {
  theme,
  Text,
  AvatarComponent,
  SelectComponent,
  ButtonAnt,
  Menu,
} from '@pfmam/pfmam';
import { MailOutlined, MenuOutlined } from '@ant-design/icons';
import { groupByProperty, objReducer } from '../../utils/common';
import CaretImg from '../../assets/images/vector.svg';
import { useSelector, useDispatch } from 'react-redux';
import { clientsFilteredSearch } from '../../services/programClientsSlice';
import { updateSiteinfo } from '../../services/siteConfigurationSlice';
import { setWebUserType } from '../../services/userSlice';
import { GetContactDetailsById,GetWebUserVisits } from '../../actions/actions';
import {
  signoutRedirect,
  multipleSessionSignoutRedirect,
} from '../../services/userService';
import { storeUser } from '../../services/userSlice';
import { Box } from '../../styles/globalStyle';
import { sizes } from '../../styles/mediaQuery';
import { Dropdown } from 'antd';
import { getsiteConfigurationInfo } from '../../services/siteConfigurationSlice';
import IdleTimerContainer from '../components/IdleTimerContainer';
import { useHistory } from 'react-router-dom';
import ConnectLogo from '../../assets/images/connect-logo.png';
import moment from 'moment';
const { Header } = Layout;

const HeaderOverrideStyle = createGlobalStyle`
  .ant-dropdown-menu-item {
    padding: 8px 12px;
    line-height: 20px;
    color: ${theme.colors.black_secondary}
  }
  .logout-dropdown-menu {
    .ant-dropdown-menu {
      padding: 0;
      box-shadow: ${theme.shadows.dropdown};
      position: fixed;
      width: 210px;
    }
  }
`;

const TopHeader = styled(Header)`
  background: ${theme.colors.white};
  box-shadow: 0px 1px 0px ${theme.shadows.sidebar};
  min-height: 76px;
  max-height: 76px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1051;
  @media ${sizes.mediaQuery('xs', 'xl')} {
    padding: 0 22px;
    width: 100%;
  }
`;
const DropdownWrapper = styled.div`
  height: 48px;
  min-width: 300px;
  & > {
    span {
      display: flex;
      width: 100%;
    }
  }
  .ant-select {
    width: 100%;
    background: ${theme.colors.white};
    color: ${theme.colors.black_secondary};
    border: 1px solid ${theme.colors.grey};
    .ant-select-selection-item {
      line-height: 44px;
    }
    .ant-select-arrow {
      color: ${theme.colors.grey_stone};
      font-size: ${theme.font_size.size_m};
      margin-top: -8px;
    }
    &.ant-select-open {
      .ant-select-selection-item {
        color: ${theme.colors.grey_stone};
        font-weight: ;
      }
    }
    .ant-select-selector {
      height: 100%;
    }
    &.ant-select-focused {
      box-shadow: ${theme.shadows.input_focus};
      border-color: ${(props) => props.linkTextColor};
    }
    .anticon-down {
      position: relative;
      svg {
        display: none;
      }
      &:after {
        content: '';
        display: block;
        background-image: url(${CaretImg});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        width: 10px;
        height: 8px;
        visibility: visible;
        position: absolute;
        right: -5px;
        top: 2px;
        z-index: 2;
      }
    }
  }
  & > span,
  .ant-select,
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100%;
  }
  .ant-select-dropdown {
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    .ant-select-item {
      &.ant-select-item-group {
        color: ${theme.colors.grey_tertiary};
        min-height: 16px;
        line-height: 16px;
        padding: 6px 12px;
        &:hover {
          background: none;
        }
      }
    }
    .ant-select-item-option-selected,
    .ant-select-item-option-active {
      &:not(.ant-select-item-option-disabled) {
        background: ${theme.colors.grey_faint};
        font-weight: normal;
      }
    }
    .ant-select-item {
      min-height: 34px;
      line-height: 20px;
      padding: 7px 12px;
      color: ${theme.colors.black_secondary};
      font-family: 'Inter';
      &:hover {
        background: ${theme.colors.grey_faint};
        font-family: 'Inter';
      }
    }
  }
`;
const ViewAll = styled.a`
  color: ${(props) => props.linkTextColor};
  font-size: ${theme.font_size.size_l};
  line-height: 20px;
  padding: 7px 12px;
  &:hover {
    color: ${(props) => props.linkTextColor};
    opacity: 0.6;
  }
  &:focus,
  &:active {
    opacity: 1;
  }
`;
const MenuButton = styled(ButtonAnt)`
  height: 44px;
  width: 44px;
  padding: 0;
  border: 0;
  display: none;
  margin-right: 14px;
  font-size: ${theme.font_size.size_l};
  color: ${theme.colors.black_secondary};
  line-height: 47px;
  @media ${sizes.mediaQuery('xs', 'xl')} {
    display: block;
  }
`;

const HeaderComponent = ({
  isSidebarOpen,
  setIsSidebarOpen,
  timelineHeader = false,
  hasClients = true,
}) => {
  const siteConfigurationInfo = useDispatch();
  const history = useHistory();
  const [userDetails, setUserDetails] = useState([]);
  const contactId = useSelector((state) => state.userSlice.user.profile.sub);
  const dispatch = useDispatch();
  const primary_color = useSelector((state) => state.siteInfo.primaryColor);
  const clientPrograms = useSelector(
    (state) => state.programsClientsSlice.programClients
  );
  const selectedClients = useSelector(
    (state) => state.programsClientsSlice.selectedClient
  );
  const getNameInitials = (userDetails) => {
    if (userDetails.mvarFirstName != null) {
      return (
        userDetails.mvarFirstName.charAt(0) + userDetails.mvarLastName.charAt(0)
      ).toUpperCase();
    }
  };
  const webUserAccessTypeDispatch = useDispatch();
  const user_logout = useSelector((state) => state.userSlice.user.id_token);
  const webUserVisitId = useSelector(
    (state) => state.userSlice.user.profile.WebUserVisit
  );
  const onMenuClick = (item) => {
    if (item.key === 'logout') {
      //dispatch(storeUser(null));
      signoutRedirect(user_logout, dispatch);
      // history.push('/logout');
    }
    if (item.key === 'profile') {
      if (selectedClients.payload && selectedClients.payload.length > 0) {
        dispatch(clientsFilteredSearch(selectedClients.payload));
      } else {
        dispatch(clientsFilteredSearch(clientPrograms));
        const programId = clientPrograms[0].programid;
        siteConfigurationInfo(getsiteConfigurationInfo({ programId }));
      }
      history.push('/profile');
    }
  };
  useEffect(() => {
    GetWebUserVisits(webUserVisitId)
        .then((response) => {
          if (response.data != null && response.data == false  ) {
            multipleSessionSignoutRedirect(dispatch, user_logout);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, []);

  useEffect(() => {
    GetContactDetailsById(contactId)
      .then((response) => {
        if (response.data != null) {
          webUserAccessTypeDispatch(
            setWebUserType(response.data.mvarWebUserAccessType)
          );
          setUserDetails(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleChange(value, option) {
    //checking if clicked client is a duplicate that contains the last 4 digits of ssnortin
    let selectClientDropdown = [];
    if (value.includes('~')) {
      var fields = value.split('~');
      selectClientDropdown = clientPrograms.filter(
        (x) =>
          x.clientname.toLowerCase().includes(fields[0].toLowerCase()) &&
          x.programname.includes(fields[1]) &&
          x.ssnortin.includes(fields[2])
      );
    } else {
      selectClientDropdown = clientPrograms.filter((x) =>
        x.clientname.toLowerCase().trim() == value.toLowerCase().trim()
      );
    }
    dispatch(updateSiteinfo(false));
    dispatch(clientsFilteredSearch(selectClientDropdown));
    history.push('/dashboard');
  }

  return (
    <>
      <HeaderOverrideStyle />
      <IdleTimerContainer></IdleTimerContainer>
      <TopHeader>
        <Box display="flex" alignitems="center">
          {!timelineHeader && (
            <MenuButton
              label={false}
              icon={<MenuOutlined />}
              type="secondary"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            />
          )}
          {timelineHeader ? (
            <Box display="flex" alignitems="center" margin="0 0 0 -32px">
              <img src={ConnectLogo} alt="Logo" />
              <Text
                color={`${theme.colors.black_secondary}`}
                fontSize="24px"
                lineHeight="32px"
                label={`Welcome, ${userDetails.mvarFirstName}`}
                margin="0 0 0 32px"
              />
            </Box>
          ) : clientPrograms.length > 1 ? (
            <DropdownWrapper
              linkTextColor={primary_color}
              id="multiple-programs-dropdown"
            >
              <SelectComponent
                onChange={handleChange}
                defaultValue={selectedClients.payload[0].clientname}
                option={groupByProperty(clientPrograms, 'programname')}
                color={`${theme.colors.black_secondary}`}
                display="groupBy"
                getPopupContainer={() =>
                  document.getElementById('multiple-programs-dropdown')
                }
                showSearch
                type="secondary"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <ViewAll
                      linkTextColor={primary_color}
                      href="/choose-client"
                    >
                      View All
                    </ViewAll>
                  </>
                )}
              />
            </DropdownWrapper>
          ) : (
            <Text
              lineHeight="32px"
              label={selectedClients.payload[0].clientname}
              color={`${theme.colors.black_secondary}`}
              fontSize={`${theme.font_size.size_3xl}`}
            />
          )}
        </Box>
        <Space size={32}>
          {/*!timelineHeader && (
            <Text
              color={`${theme.colors.black_secondary}`}
              fontSize={`${theme.font_size.size_xl}`}
              lineheight="24px"
              label="Messages"
              icon={<MailOutlined />}
            />
          )*/}
          {hasClients && (
            <div id="logout-dropdown">
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu
                    type={'basic'}
                    multiple={false}
                    mode={'inline'}
                    onClick={(item) => {
                      onMenuClick(item);
                    }}
                    items={[
                      {
                        title: 'Profile',
                        disabled: false,
                        key: 'profile',
                      },
                      {
                        title: 'Log Out',
                        disabled: false,
                        key: 'logout',
                      },
                    ]}
                    width={'210px'}
                  />
                }
                placement="bottomRight"
                overlayStyle={{ zIndex: '1052' }}
                getPopupContainer={() =>
                  document.getElementById('logout-dropdown')
                }
                overlayClassName="logout-dropdown-menu"
              >
                <AvatarComponent
                  initials={getNameInitials(userDetails)}
                  size={40}
                  style={{
                    color: `${theme.colors.white}`,
                    backgroundColor: `${theme.colors.peel_orange}`,
                    fontSize: `${theme.font_size.size_xl}`,
                    cursor: 'pointer',
                  }}
                />
              </Dropdown>
            </div>
          )}
        </Space>
      </TopHeader>
    </>
  );
};
export default HeaderComponent;
